import axios from 'axios'
import dayjs from 'dayjs'
import { auth } from '../firebase'

export const diggerApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

/*
|--------------------------------------------------------------------------
| Private Shared Functions
|--------------------------------------------------------------------------
*/
export const AUTH_HEADER = async () => {
  if (auth.currentUser) {
    const idToken = await auth.currentUser.getIdTokenResult()
    if (idToken && dayjs().isBefore(idToken.expirationTime)) {
      return `Bearer ${idToken.token}`
    } else {
      return `Bearer ${await auth.currentUser.getIdToken(true)}`
    }
  }
}
// eslint-disable-next-line
export const UID = async () => {
  const uid = window.localStorage.getItem('uid')
  if (uid) {
    return uid
  } else {
    await auth.currentUser.getIdTokenResult().then((idToken) => {
      window.localStorage.setItem('uid', idToken.claims.api.uid)
      return idToken.claims.api.uid
    })
  }
}

/*
|--------------------------------------------------------------------------
| User Functions
|--------------------------------------------------------------------------
*/
export const getAdminUser = async () => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/admins/me`, {
      headers: {
        Authorization: await AUTH_HEADER()
      }
    })
    .then((res) => {
      if (res.data.success) {
        window.localStorage.setItem('menuStatus', 'open')
        if (window.location.pathname.includes('login')) {
          if (window.location.search.includes('returnUrl')) {
            const returnUrl = new URLSearchParams(window.location.search).get(
              'returnUrl'
            )
            const url = window.location.origin + returnUrl
            window.location.href = url
          } else window.location.pathname = '/'
        }
      }
      return res.data
    })
}

export const updateAdmin = async (obj) => {
  const { adminUid, data } = obj
  // adminUid
  return axios
    .put(`${process.env.REACT_APP_API_URL}/admins/${adminUid}`, data, {
      headers: {
        Authorization: await AUTH_HEADER()
      }
    })
    .then((res) => res.data)
}

/*
|--------------------------------------------------------------------------
| Job Functions
|--------------------------------------------------------------------------
*/
export const getJobs = async () => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/jobs`, {
      headers: {
        Authorization: await AUTH_HEADER()
      }
    })
    .then((res) => res.data.data)
}

export const getSingleJob = async ({ queryKey }) => {
  // eslint-disable-next-line
  const [_key, { jobUid }] = queryKey;
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/jobs/${jobUid}`, {
      headers: {
        Authorization: await AUTH_HEADER()
      }
    })
    .then((res) => res.data.data)
}

export const createJob = async ({ ...data }) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/jobs`, data, {
      headers: {
        Authorization: await AUTH_HEADER()
      }
    })
    .then((res) => {
      return res.data
    })
}

export const publishJob = async (jobUid) => {
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/jobs/${jobUid}/publish`,
      { amountOfDays: 30 },
      {
        headers: {
          Authorization: await AUTH_HEADER()
        }
      }
    )
    .then((res) => res.data)
}

export const editJob = async (data) => {
  const { jobUid, updatedData } = data
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/jobs/${jobUid}`,
      updatedData,
      {
        headers: {
          Authorization: await AUTH_HEADER()
        }
      }
    )
    .then((res) => res.data)
}

export const deleteJob = async (jobUid) => {
  return axios
    .delete(`${process.env.REACT_APP_API_URL}/jobs/${jobUid}`, {
      headers: {
        Authorization: await AUTH_HEADER()
      }
    })
    .then((res) => res.data)
}
export const deleteJobSkills = async (data) => {
  const { jobUid, body } = data
  return axios
    .delete(`${process.env.REACT_APP_API_URL}/jobs/${jobUid}/skills`,
      {
        headers: {
          Authorization: await AUTH_HEADER()
        },
        data: body
      })
    .then((res) => res.data)
}
export const deleteJobRequirements = async (data) => {
  const { jobUid, body } = data
  return axios
    .delete(`${process.env.REACT_APP_API_URL}/jobs/${jobUid}/requirements`,
      {
        headers: {
          Authorization: await AUTH_HEADER()
        },
        data: body
      })
    .then((res) => res.data)
}

export const shortlistCandidate = async (data) => {
  const { jobUid, candidateUid } = data
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/jobs/${jobUid}/candidates/${candidateUid}/status/shortlist`,
      null,
      {
        headers: {
          Authorization: await AUTH_HEADER()
        }
      }
    )
    .then((res) => res.data)
}

export const moveToApplicantList = async (data) => {
  const { jobUid, candidateUid } = data
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/jobs/${jobUid}/candidates/${candidateUid}/status/applicant`,
      null,
      {
        headers: {
          Authorization: await AUTH_HEADER()
        }
      }
    )
    .then((res) => res.data)
}

export const placeCandidate = async (data) => {
  const { jobUid, candidateUid } = data
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/jobs/${jobUid}/candidates/${candidateUid}/place`,
      null,
      {
        headers: {
          Authorization: await AUTH_HEADER()
        }
      }
    )
    .then((res) => res.data)
}

export const voteForCandidate = async (data) => {
  const { jobUid, candidateUid, vote } = data
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/jobs/${jobUid}/candidates/${candidateUid}/vote`,
      { vote },
      {
        headers: {
          Authorization: await AUTH_HEADER()
        }
      }
    )
    .then((res) => res.data)
}

export const messageCandidate = async (data) => {
  const { jobUid, candidateUid, message } = data

  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/jobs/${jobUid}/candidates/${candidateUid}/chat`,
      { message: message },
      {
        headers: {
          Authorization: await AUTH_HEADER()
        }
      }
    )
    .then((res) => res.data)
}

export const makeEvent = async (data) => {
  const { jobUid, candidateUid, message } = data

  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/jobs/${jobUid}/candidates/${candidateUid}/make-interview-request`,
      { message: message },
      {
        headers: {
          Authorization: await AUTH_HEADER()
        }
      }
    )
    .then((res) => res.data)
}

export const makeJobOffer = async (data) => {
  const { jobUid, candidateUid, message } = data

  return axios.put(
    `${process.env.REACT_APP_API_URL}/jobs/${jobUid}/candidates/${candidateUid}/make-offer`,
    { message: message },
    {
      headers: {
        Authorization: await AUTH_HEADER()
      }
    }
  )
}

export const createJobAdmin = async (data) => {
  const { jobUid, adminUid, role } = data

  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/jobs/${jobUid}/admins`,
      { role, adminUid },
      {
        headers: {
          Authorization: await AUTH_HEADER()
        }
      }
    )
    .then((res) => res.data)
}

export const deleteJobAdmin = async (data) => {
  const { jobUid, adminUid } = data

  return axios
    .delete(
      `${process.env.REACT_APP_API_URL}/jobs/${jobUid}/admins/${adminUid}`,
      {
        headers: {
          Authorization: await AUTH_HEADER()
        }
      }
    )
    .then((res) => res.data)
}

/*
|--------------------------------------------------------------------------
| Location Functions
|--------------------------------------------------------------------------
*/
export const getLocations = async () => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/locations`, {
      headers: {
        Authorization: await AUTH_HEADER()
      }
    })
    .then((res) => res.data)
}

export const getSingleLocation = async ({ queryKey }) => {
  // eslint-disable-next-line
  const [_key, { locationUid }] = queryKey;
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/locations/${locationUid}`, {
      headers: {
        Authorization: await AUTH_HEADER()
      }
    })
    .then((res) => res.data)
}

export const createLocation = async ({ ...data }) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/locations`, data, {
      headers: {
        Authorization: await AUTH_HEADER()
      }
    })
    .then((res) => {
      window.location.pathname = '/locations'
      return res.data
    })
}

export const createLocationAdmin = async (data) => {
  const { locationUid, adminUid, role } = data

  return (
    axios.post(
      `${process.env.REACT_APP_API_URL}/locations/${locationUid}/admins`,
      { role, adminUid, locationUid },
      {
        headers: {
          Authorization: await AUTH_HEADER()
        }
      }
    ).then(res => res.data)
  )
}

export const deleteLocationAdmin = async (data) => {
  const { locationUid, adminUid } = data

  return axios
    .delete(
      `${process.env.REACT_APP_API_URL}/locations/${locationUid}/admins/${adminUid}`,
      {
        headers: {
          Authorization: await AUTH_HEADER()
        }
      }
    )
    .then((res) => res.data)
}

export const editLocation = async (data) => {
  const { locationUid, updatedData } = data
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/locations/${locationUid}`,
      updatedData,
      {
        headers: {
          Authorization: await AUTH_HEADER()
        }
      }
    )
    .then((res) => res.data)
}

export const deleteLocation = async (locationUid) => {
  return axios
    .delete(`${process.env.REACT_APP_API_URL}/locations/${locationUid}`, {
      headers: {
        Authorization: await AUTH_HEADER()
      }
    })
    .then((res) => res.data)
}

export const getLocationTypes = async ({ queryKey }) => {
  // eslint-disable-next-line
  const [_key, { companyUid }] = queryKey;
  return await axios
    .get(
      `${process.env.REACT_APP_API_URL}/companies/${companyUid}/location-type`,
      {
        headers: {
          Authorization: await AUTH_HEADER()
        }
      }
    )
    .then((res) => res.data.data)
}

/*
|--------------------------------------------------------------------------
| Company Functions
|--------------------------------------------------------------------------
*/

export const getAllCompanies = async () => {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/companies?all=true`, {
      headers: {
        Authorization: await AUTH_HEADER()
      }
    })
    .then((res) => res.data)
}

export const createCompany = async ({ ...data }) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/companies`, data, {
      headers: {
        Authorization: await AUTH_HEADER()
      }
    })
    .then((res) => {
      return res.data
    })
}

export const deleteCompany = async (companyUid) => {
  return axios
    .delete(`${process.env.REACT_APP_API_URL}/companies/${companyUid}`, {
      headers: {
        Authorization: await AUTH_HEADER()
      }
    })
    .then((res) => res.data)
}

export const editCompany = async (data) => {
  const { companyUid, updatedData } = data
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}/companies/${companyUid}`,
      updatedData,
      {
        headers: {
          Authorization: await AUTH_HEADER()
        }
      }
    )
    .then((res) => res.data)
}

export const editLocationTypes = async (data) => {
  const { companyUid, updatedData } = data
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/companies/${companyUid}/location-type`,
      updatedData,
      {
        headers: {
          Authorization: await AUTH_HEADER()
        }
      }
    )
    .then((res) => res.data)
}
export const deleteLocationTypes = async (data) => {
  const { companyUid, updatedData } = data
  return axios
    .delete(
      `${process.env.REACT_APP_API_URL}/companies/${companyUid}/location-type`,
      {
        headers: {
          Authorization: await AUTH_HEADER()
        },
        data: updatedData
      }
    )
    .then((res) => res.data)
}

export const createCompanyAdmin = async (obj) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/admins`, obj, {
      headers: {
        Authorization: await AUTH_HEADER()
      }
    })
    .then((res) => {
      return res.data
    })
}

export const deleteCompanyAdmin = async (data) => {
  const { adminUid } = data

  return axios
    .delete(
      `${process.env.REACT_APP_API_URL}/admins/${adminUid}`,
      {
        headers: {
          Authorization: await AUTH_HEADER()
        }
      }
    )
    .then((res) => res.data)
}

export const getCompanyAdmins = async () => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/admins`, {
      headers: {
        Authorization: await AUTH_HEADER()
      }
    })
    .then((res) => {
      return res.data
    })
}

export const getSingleCompany = async ({ queryKey }) => {
  // eslint-disable-next-line
  const [_key, { companyUid }] = queryKey;
  return await axios
    .get(`${process.env.REACT_APP_API_URL}/companies/${companyUid}?all=true`, {
      headers: {
        Authorization: await AUTH_HEADER()
      }
    })
    .then((res) => res.data)
}
/*
|--------------------------------------------------------------------------
| Skill Functions
|--------------------------------------------------------------------------
*/
export const searchSkills = async (name, offset = 0) => {
  // eslint-disable-next-line
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/skills/search?name=${name}&offset=${offset}`,
      {
        headers: {
          Authorization: await AUTH_HEADER()
        }
      }
    )
    .then((res) => res.data.data.skills)
}
