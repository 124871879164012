import axios from 'axios'
import { useQuery, useQueryClient } from 'react-query'
import { AUTH_HEADER } from '../constants/api'

export const useAllLocations = () => {
  return useQuery(
    'locations',
    async () => axios.get(
      `${process.env.REACT_APP_API_URL}/locations`,
      {
        headers: {
          Authorization: await AUTH_HEADER()
        }
      }
    ).then(res => res.data.data),
    {
      staleTime: 1000 * 60 * 30,
      refetchOnWindowFocus: true
    }
  )
}

export const useSingleLocation = (locationUid) => {
  const queryClient = useQueryClient()

  return useQuery(
    ['location', locationUid],
    async () => axios.get(
      `${process.env.REACT_APP_API_URL}/locations/${locationUid}`,
      {
        headers: {
          Authorization: await AUTH_HEADER()
        }
      }
    ).then(res => res.data.data),
    {
      initialData: () => {
        if (locationUid) {
          const cachedLocations = queryClient.getQueryData('jobs')
          if (cachedLocations !== undefined && Array.isArray(cachedLocations)) return cachedLocations?.find(x => x.uid === locationUid)
          else if (cachedLocations !== undefined && typeof cachedLocations === 'object') return cachedLocations
        }
      },
      initialStale: true,
      enabled: !!locationUid
    }
  )
}

