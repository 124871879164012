import React, { useEffect, useState } from 'react'

// Components
import { CreateDetails } from './CreateDetails'
import { CreateExperience } from './CreateExperience'
import { CreateDocuments } from './CreateDocuments'

// PACKAGES
import { Form, Formik } from 'formik'
import { Link, useHistory, useLocation } from 'react-router-dom'
import * as Yup from 'yup'
import { useMutation, useQueryClient } from 'react-query'
import { createJob } from '../../constants/api'
import { Alert } from '../shared/Alert'
import { useAlert } from '../../hooks/useAlert'
import { storage } from '../../firebase'
import { FileDropzone } from '../shared/forms/FileDropzone'
import { Loader } from '../shared/Loader'
import { Job } from './Job'
import { ReactComponent as PlusIcon } from '../shared/icons/plus.svg'
import { useAllLocations } from '../../hooks/useLocations'
import { TextOnlyEditor } from '../layout/TextOnlyEditor'

const validationSchema = Yup.object().shape({
  jobTitle: Yup.string().min(3, 'Too short').max(50, 'Too long').required('Job title is required'),
  minSalary: Yup.number().required(),
  maxSalary: Yup.number().moreThan(Yup.ref('minSalary'), 'Must be greater than minimum salary').required(),
  experience: Yup.array().of(
    Yup.object({
      experience: Yup.number(),
      skill: Yup.string()
    })
  )
})

export const CreateJob = () => {
  const [jobSkills, setJobSkills] = useState([])
  const [image, setImage] = useState(null)
  const [dlUrl, setDlUrl] = useState(null)
  const [loading, setLoading] = useState(null)
  const [editor, setEditor] = useState(null)

  const urlLocation = useLocation().state
  const history = useHistory()
  const queryClient = useQueryClient()

  // API INTERACTION
  const { mutateAsync, isLoading, error } = useMutation(createJob, {
    onSuccess: () => {
      queryClient.invalidateQueries('jobs')
      history.push('/')
    },
    onError: () => {
      openAlert(error?.message)
    }
  })
  // const { data: locations, isLoading: locationLoading } = useQuery('locations', getLocations)
  const locationsQuery = useAllLocations()

  const { isActive, message, openAlert } = useAlert()

  const onSubmit = async (data) => {
    await mutateAsync({ ...data })
  }

  useEffect(() => {
    if (image !== null) {
      setLoading(true)
      const file = image[0]
      const fileToUpload = storage.ref(`digger/uploads/jobs/${file.name}`)
      fileToUpload
        .put(file)
        .then(snap => {
          fileToUpload
            .getDownloadURL()
            .then(url => {
              setDlUrl(url)
              setLoading(false)
            })
        })
    }
  }, [image])

  useEffect(() => {
    console.log(jobSkills)
  }, [jobSkills])

  const initialValues = {
    jobTitle: '',
    location: '',
    jobType: 'fullTime',
    maxSalary: '',
    minSalary: '',
    visibility: true,
    experience: [],
    documents: []
  }

  if (locationsQuery.isLoading) {
    return (
      <>
        <Job loading />
        <Job loading />
        <Job loading />
      </>
    )
  }

  return (
    <>
      {!locationsQuery.isLoading && locationsQuery.data && locationsQuery.data.length > 0
        ? (
          <Formik
            validateOnChange
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (data, { setSubmitting, resetForm }) => {
              setSubmitting(true)
              // DESTRUCTURE FORM DATA
              const jobToUpload = {
                locationUid: data.location,
                title: data.jobTitle,
                descriptionAsJSON: await editor.save().then(data => data),
                minSalary: data.minSalary,
                maxSalary: data.maxSalary,
                isPartTime: !!data.jobType !== 'fullTime',
                photoUrl: dlUrl,
                jobRequirements: data.documents,
                jobSkills: jobSkills.map((skill, i) => {
                  let weight
                  if (4 - i >= 0) weight = 4 - i
                  else weight = 1

                  const weightAsString = weight.toString()

                  return ({ ...skill, weight: weightAsString })
                })
              }
              onSubmit(jobToUpload)
            }}
          >
            {({ isSubmitting, values }) => (
              <Form autoComplete='off'>
                <Alert isActive={isActive} message={message} />

                <h3>Job Builder</h3>
                <CreateDetails data={locationsQuery.data} locationToEdit={urlLocation} />

                <h3>Skills</h3>
                <p className='feedback'>We will use this information to match the best candidates for this job.</p>
                <CreateExperience isEditing={!!urlLocation} values={values} jobSkills={jobSkills} setJobSkills={setJobSkills} />

                <h3>Required Documents</h3>
                <p className='feedback'>The candidate will be required to provde proof of each document listed here</p>
                <CreateDocuments values={values} />

                <h3>Job Image</h3>
                <FileDropzone className='locImage marginBottom' setDocs={setImage} preview={image && image.length > 0 && image[0].preview}>
                  {loading
                    ? <Loader />
                    : image
                      ? (
                        <div className='image__blur'>
                          <h3>Change Job Image</h3>
                          <p>This will be seen across digger and you'll be recognized by this</p>
                        </div>
                      )
                      : (
                        <div>
                          <h3>Upload a Job Image</h3>
                          <p>This will be seen across digger and you'll be recognized by this</p>
                        </div>
                      )}
                </FileDropzone>

                <h3>Description</h3>
                <div className='requirementsCard'>
                  <TextOnlyEditor disableImages setInstance={setEditor} />
                </div>
                <button disabled={isLoading || loading || !dlUrl || jobSkills.length < 1} className='btnPrimary' type='submit'>
                  {isLoading ? 'Loading...' : 'Submit'}
                </button>
                {/* <button className='btnPrimary' type='submit'>
                  {isLoading ? 'Loading...' : 'Submit'}
                </button> */}
              </Form>
            )}
          </Formik>
        ) : (
          <Link
            className='jobCard outlined'
            to='/location/create'
          >
            <div className='job__feedback'>
              <PlusIcon />
              <h3>No locations yet!</h3>
              <p>Create a location before you start building jobs</p>
            </div>
          </Link>
        )}
    </>
  )
}
