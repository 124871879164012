import React from 'react'

export const StatusPill = ({ type, message }) => {
  // const typeToUpper = type
  // const newType = typeToUpper.charAt(0).toUpperCase() + typeToUpper.slice(1)
  return (
    <>
      {type && (
        <div className={`statusPill statusPill--${type}`}>
          {message}
        </div>
      )}
    </>

  )
}
