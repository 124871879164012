import React, { useEffect, useState } from 'react'

// Components
import { ShortlistUser } from './ShortlistUser'
import { ShortlistChat } from './ShortlistChat'
import { FileDropzone } from '../shared/forms/FileDropzone'
import { Input } from '../shared/forms/Input'
import { ReactComponent as UploadIcon } from '../shared/icons/upload.svg'
import { ReactComponent as ClockIcon } from '../shared/icons/clock.svg'
import { ReactComponent as OfferIcon } from '../shared/icons/handshake.svg'
import { Loader } from '../shared/Loader'

// Packages
import { storage } from '../../firebase'
import { Field, Form, Formik } from 'formik'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

// Icons
import { ReactComponent as PlusIcon } from '../shared/icons/plus.svg'
import { ReactComponent as ArrowIcon } from '../shared/icons/arrow.svg'
import { useMutation } from 'react-query'
import { makeEvent, makeJobOffer, messageCandidate } from '../../constants/api'
import { useParams } from 'react-router'
import { Alert } from '../shared/Alert'
import { useAlert } from '../../hooks/useAlert'
import { Dropdown } from '../shared/layout/Dropdown'
import * as Yup from 'yup'

dayjs.extend(utc)

const validationSchema = Yup.object().shape({
  title: Yup.string().min(3, 'Too short').max(50, 'Too long'),
  date: Yup.date().min(new Date(), 'Date must be in the future'),
  time: Yup.string()
})

export const ShortlistModal = ({ modalState, person, handleModal }) => {
  const { slug: jobUid } = useParams()
  const [docs, setDocs] = useState(null)
  const [offerDoc, setOfferDoc] = useState(null)
  const [loading, setLoading] = useState(false)
  const [offerDocLoading, setDocLoading] = useState(false)
  const [offerDocUrl, setOfferDocUrl] = useState('')
  const [open, setOpen] = useState(false)

  // API INTERACTION
  const { mutateAsync: sendAsyncMessage } = useMutation(messageCandidate, {
    onError: (e) => {
      openAlert(e.message)
    }
  })

  const { mutateAsync: sendAsyncEvent } = useMutation(makeEvent, {
    onError: (e) => {
      openAlert(e.message)
    },
    onSuccess: () => setOpen(false)
  })

  const { mutateAsync: makeOfferAsync } = useMutation(makeJobOffer, {
    onError: (e) => {
      openAlert(e.message)
    },
    onSuccess: () => setOpen(false)
  })
  const { isActive, message, openAlert } = useAlert()

  // let docUrl = '';

  const handleSubmit = async (values, resetForm) => {
    if (
      values.message === '' &&
      values.title === undefined &&
      values.offerAmount === null
    ) {
      window.alert('You cannot send an empty message')
    } else {
      try {
        if (docs) {
          const file = docs[docs.length - 1]
          await uploadImage(file, values.message)
          resetForm()
        } else if (values.title !== undefined) {
          await sendEvent(values)
          resetForm()
        } else if (values.offerAmount !== null) {
          await sendOffer(values)
        } else {
          await sendMessage(values.message)
          resetForm()
        }
        setOpen(false)
      } catch (error) {
        console.error(error)
      }
    }
  }

  const uploadImage = (file, message) => {
    setLoading(true)
    const fileToUpload = storage.ref(`digger/uploads/chat/${file.name}`)
    fileToUpload
      .put(file)
      .then((snapshot) => {
        fileToUpload.getDownloadURL().then((url) => {
          // setDocUrl(url)
          sendMessage(message, url)
          setLoading(false)
        })
      })
      .catch((error) => {
        setLoading(false)
        console.error(error)
      })
  }

  const uploadOfferDoc = (file) => {
    setDocLoading(true)
    const fileToUpload = storage.ref(`digger/uploads/${file.name}`)
    fileToUpload
      .put(file)
      .then((snapshot) => {
        fileToUpload.getDownloadURL().then((url) => {
          setOfferDocUrl(url)
          setDocLoading(false)
        })
      })
      .catch((error) => {
        setDocLoading(false)
        console.error(error)
      })
  }

  useEffect(() => {
    if (offerDoc !== null) {
      uploadOfferDoc(offerDoc)
    }
  }, [offerDoc])

  const sendMessage = async (message, url = null) => {
    await sendAsyncMessage({
      jobUid,
      candidateUid: person.candidate.uid,
      message: {
        type: docs ? 'doc' : 'text',
        content: {
          text: message,
          url: url
        }
      }
    })
  }

  const sendEvent = async (event) => {
    await sendAsyncEvent({
      jobUid,
      candidateUid: person.candidate.uid,
      message: {
        type: 'meeting',
        content: {
          event: {
            title: event.title,
            description: '',
            date: dayjs(`${event.date}:${event.time}`).format(),
            duration: {
              hours: event.duration > 30 ? 1 : 0,
              minutes: event.duration <= 30 ? event.duration : 0
            },
            attendees: [
              {
                name: person.candidate.fullName,
                email: person.candidate.email
              }
            ]
          }
        }
      }
    })
  }

  const sendOffer = async (offer) => {
    await makeOfferAsync({
      jobUid,
      candidateUid: person.candidate.uid,
      message: {
        type: offerDoc && offerDocUrl !== '' ? 'doc' : 'text',
        content: {
          text: offer.offerAmount,
          url: offerDocUrl !== '' && offerDocUrl
        }
      }
    })
  }

  useEffect(() => {
    if (docs) {
      setOpen(false)
    }
  }, [docs])

  return (
    <>
      <Alert isActive={isActive} message={message} />

      <FileDropzone className='modalInner' setDocs={setDocs} disableClick>
        <div onClick={() => handleModal()} className='icon-button'>
          <ArrowIcon />
        </div>
        <ShortlistUser
          upvotes={person.upvote}
          downvotes={person.downvote}
          user={person}
        />
        <div className='horizontalDivider' />
        <ShortlistChat modalState={modalState} channel={person.uid} />
        <Formik
          initialValues={{
            message: '',
            time: '12:00:00',
            date: dayjs(new Date(), 'yyyy-MM-dd'),
            duration: '30',
            offerAmount: null
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            // console.log(offerDoc);
            handleSubmit(values, resetForm)
            setDocs(null)
          }}
          validateOnChange
        >
          {!loading ? (
            <Form className='formContainer formContainer--bottom'>
              {docs &&
                docs.map((file) => (
                  <img
                    key={file.name}
                    className='imagePreview'
                    src={file.preview}
                    alt='preview'
                  />
                ))}
              <div className='formGroup formGroup--withBtn'>
                {/* <FileDropzone setDocs={setDocs}>
                </FileDropzone> */}
                <Dropdown open={open} setOpen={setOpen} above icon={<PlusIcon />}>
                  <Dropdown.Menu name='main'>
                    <FileDropzone setDocs={setDocs}>
                      <Dropdown.Item leftIcon={<UploadIcon />}>
                        Upload Image
                      </Dropdown.Item>
                    </FileDropzone>
                    <Dropdown.Item
                      goToMenu='interview'
                      leftIcon={<ClockIcon />}
                    >
                      Schedule Interview
                    </Dropdown.Item>
                    <Dropdown.Item goToMenu='offer' leftIcon={<OfferIcon />}>Make offer</Dropdown.Item>
                  </Dropdown.Menu>
                  <Dropdown.Menu name='offer' isSecondary>
                    <Dropdown.Item
                      marginBottom
                      goToMenu='main'
                      leftIcon={<ArrowIcon />}
                    >
                      <h2>Make offer</h2>
                    </Dropdown.Item>
                    <label className='bold' htmlFor='title'>
                      Amount
                    </label>
                    <Input
                      type='number'
                      placeholder='Offer Amount'
                      name='offerAmount'
                    />
                    <FileDropzone
                      className='locImage'
                      preview={
                        offerDoc && offerDoc.length > 0 && offerDoc[0].preview
                      }
                      setDocs={setOfferDoc}
                    >
                      {offerDocLoading ? (
                        <Loader />
                      ) : offerDoc ? (
                        <div>
                          <h3>Change offer letter</h3>
                        </div>
                      ) : (
                        <div>
                          <h3>Upload offer letter (optional)</h3>
                        </div>
                      )}
                    </FileDropzone>
                    <button type='submit' className='btnPrimary'>
                      Send Offer
                    </button>
                  </Dropdown.Menu>
                  <Dropdown.Menu isSecondary name='interview'>
                    <Dropdown.Item
                      marginBottom
                      goToMenu='main'
                      leftIcon={<ArrowIcon />}
                    >
                      <h2>Interview</h2>
                    </Dropdown.Item>
                    <label className='bold' htmlFor='title'>
                      Title
                    </label>
                    <Input placeholder='Interview Title' name='title' />
                    <label className='bold' htmlFor='title'>
                      Date
                    </label>
                    <Input name='date' type='date' />
                    <label className='bold' htmlFor='time'>
                      Start Time
                    </label>
                    <Input name='time' type='time' />
                    <label className='bold' htmlFor='title'>
                      Duration
                    </label>
                    <div className='toggle'>
                      <Field id='1' type='radio' name='duration' value='30' />
                      <label htmlFor='1'>30 Mins</label>

                      <Field id='2' type='radio' name='duration' value='60' />
                      <label htmlFor='2'>60 Mins</label>
                    </div>
                    <button
                      type='submit'
                      style={{ margin: '1rem 0' }}
                      className='btnPrimary fit'
                    >
                      Create Interview
                    </button>
                  </Dropdown.Menu>
                </Dropdown>
                <Field id='message' name='message' />
                <button className='btnPrimary' type='submit'>
                  Send
                </button>
              </div>
            </Form>
          ) : (
            <p>Loading...</p>
          )}
        </Formik>
      </FileDropzone>
    </>
  )
}
