import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import * as yup from 'yup'
import { useAlert } from '../../hooks/useAlert'
import { auth } from '../../firebase'
import { Input } from '../shared/forms/Input'
import { Alert } from '../shared/Alert'
import { Link } from 'react-router-dom'
import { ReactComponent as YoutubeIcon } from '../shared/icons/youtube.svg'
import { Loader } from '../shared/Loader'

const validationSchema = yup.object({
  email: yup.string().email().required('Please enter your email')
})

export const ResetPassword = () => {
  const { isActive, message, openAlert } = useAlert()
  const [loading, setLoading] = useState(false)

  return (
    <div className='authPage__wrapper'>
      <Alert isActive={isActive} message={message} />
      <div className='auth__container'>
        <div className='auth__content'>
          <h3>Digger.</h3>
          <p>Where jobs find you</p>

          <div className='socials__container'>
            <div className='social__item'>
              <div className='social__image'>
                <YoutubeIcon />
              </div>
              <div className='social__details'>
                <p className='social__title'>Digger Learning</p>
                <p className='social__body'>Find us on youtube.</p>
              </div>
            </div>
          </div>
        </div>
        <div className='auth__form'>
          <h4>Reset password</h4>
          <Formik
            validateOnChange
            initialValues={{
              email: ''
            }}
            validationSchema={validationSchema}
            onSubmit={async (data) => {
              const config = {
                url: process.env.REACT_APP_FORGOT_PASSWORD_REDIRECT,
                handleCodeInApp: true
              }
              setLoading(true)
              auth
                .sendPasswordResetEmail(data.email, config)
                .then(() => {
                  openAlert('Check your email for password reset link')
                  setLoading(false)
                })
                .catch(error => {
                  setLoading(false)
                  openAlert(error.message)
                })
            }}
          >
            {({ values, errors }) => (
              <Form className='formContainer' autoComplete='off'>
                <div className='formGroup noMargin'>
                  <Input type='email' name='email' id='email' placeholder='Enter your email' />
                </div>
                <button disabled={loading} className='btnPrimary fullWidth' type='submit'>{loading ? <Loader /> : 'Continue'}</button>
              </Form>
            )}
          </Formik>
          <div className='alt__links'>
            <Link className='button__link' to='/login'>Login</Link>
          </div>
        </div>
      </div>
    </div>
  )
}
