import React from 'react'
import { useGlobalStateContext } from '../../constants/UseCtx'
import { ClaimButton } from '../shared/claim/ClaimButton'

// Icons
import { ReactComponent as ZoomIcon } from '../shared/icons/zoom.svg'


export const SearchBar = () => {
  const { currentMenu } = useGlobalStateContext()

  if (currentMenu === 'closed') return null

  return (
    <form action=''>
      <div className='searchContainer'>
        {/* <img classNam='icon' src='/assets/logos/company_logo.png' alt='' /> */}
        <ClaimButton icon={<ZoomIcon />} />
        <div className='vertSpacer' />
        <input type='text' placeholder='Search by Job, Company or Keywords….' />
      </div>
    </form>
  )
}
