export const setEdjs = (blocks, isPreview = false) => {
  let convertedHtml = ''
  // eslint-disable-next-line
  blocks.map((block) => {
    //   console.log(block)
    let cssCLass = ''
    convertedHtml += '<div>'
    switch (block.type) {
      case 'header':
        if (isPreview) convertedHtml += `<p class="heading" >${block.data.text}</p>`
        else convertedHtml += `<h${block.data.level}>${block.data.text}</h${block.data.level}>`
        break
      case 'embed':
        if (!isPreview) convertedHtml += `<div><iframe class="editor__video" src="${block.data.embed}" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe></div>`
        break
      case 'paragraph':
        convertedHtml += `<p>${block.data.text}</p>`
        break
      case 'delimiter':
        if (!isPreview) convertedHtml += '<hr />'
        break
      case 'image':
        if (!isPreview) {
          if (block.data.widthBorder === 'true') {
            cssCLass += ' border'
          }

          if (block.data.stretched === 'ture') {
            cssCLass += 'stretched'
          }

          if (block.data.withBackground === 'true') {
            cssCLass += ' background'
          }

          convertedHtml += `<img class="img-fluid" ${cssCLass} src="${block.data.file.url}" title="${block.data.caption}" /><br /><em>${block.data.caption}</em>`
        }

        break

      case 'list':

        if (!isPreview) {
          if (block.data.style === 'ordered') {
            convertedHtml += '<ul class="editor__ul">'
            block.data.items.forEach(function (li) {
              convertedHtml += `<li class="editor__ul ordered">${li}</li>`
            })
            convertedHtml += '</ul>'
          } else {
            convertedHtml += '<ol class="editor__ol">'
            block.data.items.forEach(function (li) {
              convertedHtml += `<li class="editor__ul unordered">${li}</li>`
            })
            convertedHtml += '</ol>'
          }
        }

        break

      default:
        break
    }

    convertedHtml += '</div>'
  })
  return convertedHtml
}

// const bioAsJson = { time: 1620385137395, blocks: [{ id: 'kx9HTRU7qa', type: 'header', data: { text: 'HELLO', level: 2 } }, { id: 'PLBxlqzLto', type: 'paragraph', data: { text: 'JRTRTYRTHRTH' } }, { id: 'aMFCuzDwoR', type: 'paragraph', data: { text: '<b>Helo</b>' } }, { id: '7Ji3BRMnGH', type: 'paragraph', data: { text: '<i>123</i>' } }, { id: 'RAlkv8f-4V', type: 'paragraph', data: { text: '<a href="https://github.com/editor-js/image">Link here</a>' } }, { id: '-2y-tYANTo', type: 'image', data: { file: { url: 'https://firebasestorage.googleapis.com/v0/b/diggerapp-api.appspot.com/o/EditorJS%2Fimages%2Fenchanted-crossbow.gif?alt=media&token=a940db81-3f6a-4b11-846e-21add85d835b' }, caption: '', withBorder: false, stretched: false, withBackground: false } }, { id: 'Q_wPcHZz9M', type: 'embed', data: { service: 'youtube', source: 'https://www.youtube.com/watch?v=TVLURx2nFqw', embed: 'https://www.youtube.com/embed/TVLURx2nFqw', width: 580, height: 320, caption: 'CAPTION' } }, { id: 'POyMhA9eCr', type: 'list', data: { style: 'ordered', items: ['ytjtyj', 'uktiu', 'kutuk', 'uyfky'] } }, { id: 'hal1s4DRRk', type: 'list', data: { style: 'unordered', items: ['jtytyj', 'ymkuy', 'mfyu', 'ukuk'] } }], version: '2.21.0' }
