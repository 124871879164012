import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useHistory, useParams } from 'react-router'
import { useAlert } from '../../hooks/useAlert'
import { useJob } from '../../hooks/useJobs'
import { useAllLocations } from '../../hooks/useLocations'
import { Editor } from '../layout/Editor'
import { Alert } from '../shared/Alert'
import { FileDropzone } from '../shared/forms/FileDropzone'
import { Loader } from '../shared/Loader'
import { CreateDetails } from './CreateDetails'
import { CreateDocuments } from './CreateDocuments'
import { Job } from './Job'
import { deleteJobRequirements, deleteJobSkills, editJob } from '../../constants/api'
import { storage } from '../../firebase'
import { CreateExperience } from './CreateExperience'

export const EditJob = () => {
  // STATE
  const [editor, setEditor] = useState(null)
  const [loading, setLoading] = useState(false)
  const [dlUrl, setDlUrl] = useState(null)
  const [docs, setDocs] = useState(null)
  const [jobSkills, setJobSkills] = useState(null)
  const [deletedSkills, setDeletedSkills] = useState([])
  const [deletedDocs, setDeletedDocs] = useState([])

  // CONSTANTS
  const { slug: jobUid } = useParams()
  const { isActive, message, openAlert } = useAlert()
  const { push } = useHistory()
  const queryClient = useQueryClient()

  // API
  const jobQuery = useJob(jobUid)
  const locationsQuery = useAllLocations()
  const { mutateAsync: runEditJob, isLoading } = useMutation(editJob, {
    onError: (error) => openAlert(error.message)
  })
  const { mutateAsync: deleteSkills } = useMutation(deleteJobSkills, { onError: (error) => openAlert(error.message) })
  const { mutateAsync: deleteDocs } = useMutation(deleteJobRequirements, { onError: (error) => openAlert(error.message) })

  useEffect(() => {
    if (docs !== null) {
      setLoading(true)
      const file = docs[0]
      const fileToUpload = storage.ref(`digger/uploads/${file.name}`)
      fileToUpload
        .put(file)
        .then(snap => {
          fileToUpload
            .getDownloadURL()
            .then(url => {
              setDlUrl(url)
              setLoading(false)
            })
        })
    }
  }, [docs])

  useEffect(() => {
    if (!jobQuery.isLoading && jobQuery.data && jobQuery.data.datePublished) push(`/jobs/${jobUid}`)
    // eslint-disable-next-line
  }, [jobQuery.isLoading, jobQuery.data])

  useEffect(() => {
    if (!jobQuery.isLoading && jobQuery.data) {
      setJobSkills(jobQuery.data.jobSkills.map(({ experience, uid, skillUid, skill: { name } }) => ({ experience, uid, id: uid, skillUid, name, disabled: true })))
    }
  }, [jobQuery.isLoading, jobQuery.data])

  return (
    <>
      {!locationsQuery.isLoading && !jobQuery.isLoading && jobSkills
        ? (
          <Formik
            initialValues={{
              jobTitle: jobQuery.data.title,
              maxSalary: jobQuery.data.maxSalary,
              minSalary: jobQuery.data.minSalary,
              jobType: jobQuery.data.isPartTime ? 'partTime' : 'fullTime',
              location: jobQuery.data.location.uid,
              documents: jobQuery.data.jobRequirements.map(({ requirement, uid }) => ({ requirement, id: uid, uid, disabled: true })),
              experience: jobSkills
            }}
            onSubmit={async (data) => {
              setLoading(true)
              await runEditJob({
                jobUid,
                updatedData: {
                  locationUid: data.location,
                  title: data.jobTitle,
                  minSalary: data.minSalary,
                  maxSalary: data.maxSalary,
                  photoUrl: dlUrl || jobQuery.data.photoUrl,
                  isPartTime: !!data.jobType !== 'fullTime',
                  jobRequirements: data.documents,
                  jobSkills: jobSkills,
                  descriptionAsJSON: await editor.save().then(data => data)
                }
              })

              if (deletedSkills.length > 0) await deleteSkills({ jobUid, body: deletedSkills })
              if (deletedDocs.length > 0) await deleteDocs({ jobUid, body: deletedDocs })

              queryClient.invalidateQueries('job')
              queryClient.invalidateQueries('jobs')
              setLoading(false)
              push(`/jobs/${jobUid}`)
            }}
          >
            {({ values, errors }) => (
              <Form autoComplete='off'>
                <Alert isActive={isActive} message={message} />
                <h3>Job Builder</h3>
                <CreateDetails data={locationsQuery.data} />

                <CreateExperience deletedSkills={deletedSkills} setDeletedSkills={setDeletedSkills} values={values} jobSkills={jobSkills} setJobSkills={setJobSkills} />

                <h3>Required Documents</h3>
                <p className='feedback'>The candidate will be required to provde proof of each document listed here</p>
                <CreateDocuments deletedDocs={deletedDocs} setDeletedDocs={setDeletedDocs} values={values} />

                <h3>Job Image</h3>
                <FileDropzone className='locImage marginBottom' setDocs={setDocs} preview={docs && docs.length > 0 ? docs[0].preview : jobQuery.data.photoUrl}>
                  {loading
                    ? <Loader />
                    : (
                      <div className='image__blur'>
                        <h3>Change Job Image</h3>
                        <p>This will be seen across digger and you'll be recognized by this</p>
                      </div>
                    )}
                </FileDropzone>
                <h3>Description</h3>
                <div className='requirementsCard'>
                  <Editor initial={jobQuery.data.descriptionAsJSON} setInstance={setEditor} />
                </div>
                <button disabled={isLoading || loading} className='btnPrimary' type='submit'>
                  {isLoading || loading ? 'Loading...' : 'Submit'}
                </button>
              </Form>
            )}
          </Formik>
        )
        : <Job loading />}
    </>
  )
}
