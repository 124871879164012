import { Field, FieldArray, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useHistory, useParams } from 'react-router'
import { deleteLocationTypes, editCompany, editLocationTypes } from '../../constants/api'
import { storage } from '../../firebase'
import { useAlert } from '../../hooks/useAlert'
import { useCompany } from '../../hooks/useCompanies'
import { Job } from '../jobs/Job'
import { Alert } from '../shared/Alert'
import { FileDropzone } from '../shared/forms/FileDropzone'
import { Loader } from '../shared/Loader'

export const CompanyEdit = () => {
  // STATE
  const [dlUrl, setDlUrl] = useState(null)
  const [docs, setDocs] = useState(null)
  const [loading, setLoading] = useState(false)
  const [deletedTypes, setDeletedTypes] = useState([])

  // CONSTANTS
  const { slug: companyUid } = useParams()
  const { isActive, message, openAlert } = useAlert()
  const { push } = useHistory()
  const queryClient = useQueryClient()

  // API
  const companyQuery = useCompany(companyUid)
  const { mutateAsync: runEditCompany, isLoading } = useMutation(editCompany, { onError: (error) => openAlert(error.message) })
  const { mutateAsync: runDeleteTypes } = useMutation(deleteLocationTypes, {
    onSuccess: () => {
      queryClient.invalidateQueries('company')
      queryClient.invalidateQueries('companies')
      push(`/companies/${companyUid}`)
    },
    onError: () => openAlert('Error while deleting location types'),
    onSettled: () => {
      setLoading(false)
    }
  })
  const { mutateAsync: runEditTypes } = useMutation(editLocationTypes, { onError: (error) => openAlert(error.message) })

  // EFFECTS
  useEffect(() => {
    if (docs !== null) {
      setLoading(true)
      const file = docs[0]
      const fileToUpload = storage.ref(`digger/uploads/${file.name}`)
      fileToUpload
        .put(file)
        .then(snap => {
          fileToUpload
            .getDownloadURL()
            .then(url => {
              setDlUrl(url)
              setLoading(false)
            })
        })
    }
  }, [docs])

  return (
    <>
      <Alert isActive={isActive} message={message} />
      {!companyQuery.isLoading && companyQuery.data
        ? (
          <Formik
            validateOnChange
            initialValues={{
              name: companyQuery.data.title,
              locationTypes: companyQuery.data.locationTypes.filter(type => type.name).map(({ name, uid }) => ({ name, uid }))
            }}
            onSubmit={async (data) => {
              setLoading(true)
              await runEditCompany({
                companyUid,
                updatedData: {
                  title: data.name,
                  photoUrl: dlUrl || companyQuery.data.photoUrl
                }
              })
              await runEditTypes({
                companyUid,
                updatedData: data.locationTypes
              })
              await runDeleteTypes({
                companyUid,
                updatedData: deletedTypes
              })
            }}
          >
            {({ values, isSubmitting }) => (
              <Form autoComplete='off'>
                <div className='requirementsCard'>
                  <div className='formContainer'>

                    <div className='formGroup'>
                      <label className='bold' htmlFor='name'>Company Name</label>
                      <Field
                        placeholder='Company Name'
                        name='name'
                        type='text'
                      />
                    </div>
                  </div>
                  {/* <FileDropzone className='locImage' setDocs={setDocs} preview={docs && docs.length > 0 && docs[0].preview}> */}
                  <FileDropzone className='locImage' setDocs={setDocs} preview={docs && docs.length > 0 ? docs[0].preview : companyQuery.data.photoUrl}>
                    {loading
                      ? <Loader />
                      : (
                        <div className='image__blur'>
                          <h3>Change company image</h3>
                          <p>This will be seen across digger and you'll be recognized by this.</p>
                        </div>
                      )}
                  </FileDropzone>
                </div>

                <h3>Location Types</h3>
                <p className='feedback'>These will be used to differentiate between your locations. E.g. Head Office and Remote</p>
                <div className='requirementsCard'>
                  <FieldArray name='locationTypes'>
                    {({ push, remove }) => (
                      <div>
                        {values.locationTypes.map((type, i) => {
                          return (
                            <div key={i} className='formContainer noGrid'>
                              <div className='formGroup formGroup--withBtn inline'>
                                <Field
                                  type='text'
                                  placeholder='Location Type'
                                  name={`locationTypes[${i}].name`}
                                />
                              </div>
                              <button type='button' className='btnPrimary remove' onClick={() => remove(i) & setDeletedTypes([...deletedTypes, type])}>x</button>
                            </div>
                          )
                        })}
                        <button className='btnPrimary' type='button' onClick={() => push()}>
                      Add
                        </button>
                      </div>
                    )}
                  </FieldArray>
                </div>
                <button className='btnPrimary' type='submit'>{isLoading || loading ? <Loader /> : 'Submit'}</button>
              </Form>
            )}
          </Formik>
        )
        : (<Job loading />)}
    </>
  )
}
