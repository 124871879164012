import { ErrorMessage, Field } from 'formik'
import React from 'react'

export const Input = ({ onInput, list, name, label, placeholder, index, type = 'text', required = false }) => {
  return (
    <>
      <Field
        placeholder={placeholder}
        required={required}
        autoComplete='off'
        label={label}
        list={list}
        name={name}
        type={type}
        onInput={e => onInput && onInput(e.target.value, index)}
      />
      <p className='input__error'><ErrorMessage name={name} /></p>
    </>
  )
}
