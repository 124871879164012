import React, { useMemo } from 'react'

// Packages
import { Link } from 'react-router-dom'

// Components
import { Job } from './Job'
import { UID } from '../../constants/api'
import { ReactComponent as PlusIcon } from '../shared/icons/plus.svg'
import { useGlobalDispatchContext } from '../../constants/UseCtx'
import { useJobs } from '../../hooks/useJobs'

export const Jobs = () => {
  const dispatch = useGlobalDispatchContext()

  const jobQuery = useJobs()

  useMemo(() => {
    const determineJobs = async () => {
      const uid = await UID()
      let createdBy = []
      if (uid && !jobQuery.isLoading && jobQuery.data) {
        // eslint-disable-next-line
        createdBy = jobQuery.data.filter(job => {
          if (job.createdBy.uid === uid) return true
          else if (job.jobAdmins.findIndex(jobAdmin => {
            return jobAdmin.adminUid === uid
          }) > -1) return true
          else return false
        })
        createdBy.forEach((job) => {
          if (job.createdByUid === uid) job.adminType = 'Creator'
          else {
            job.adminType = job.jobAdmins[job.jobAdmins.findIndex(jobAdmin => {
              return jobAdmin.adminUid === uid
            })].role
          }
        })
      }
      dispatch({ type: 'CTX_MENU', data: JSON.stringify({ key: 'jobs', data: createdBy }) })
    }

    determineJobs()

    return () => dispatch({ type: 'CTX_MENU', data: null })
  }, [dispatch, jobQuery.isLoading, jobQuery.data])

  if (jobQuery.isLoading) {
    return (
      <Job loading />
    )
  }
  return (
    <>
      {!jobQuery.isLoading && jobQuery.data && jobQuery.data.length > 0
        ? jobQuery.data.map((job, i) => (
          <Job key={job.uid} job={job} />
        ))
        : (
          <Link
            className='jobCard outlined'
            to='/job/create'
          >
            <div className='job__feedback'>
              <PlusIcon />
              <h3>Click here to start building Jobs</h3>
              <p>Build a job for a location and they will appear here.</p>
            </div>
          </Link>
        )}
    </>
  )
}
