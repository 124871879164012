import React from 'react'
import { render } from 'react-dom'
import { App } from './App'
import { GlobalProvider } from './constants/UseCtx'
import { AuthProvider } from './hooks/useAuth'
import './styles/App.scss'

// REACT QUERY
import { ReactQueryDevtools } from 'react-query/devtools'
import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient()

render(
  <QueryClientProvider client={queryClient}>
    <AuthProvider>
      <GlobalProvider>
        <App />
        <ReactQueryDevtools initialIsOpen={false} />
      </GlobalProvider>
    </AuthProvider>
  </QueryClientProvider>,
  document.getElementById('root')
)
