import React from 'react'
import db from '../../firebase'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

// Firebase

export const ShortlistChat = ({ channel, modalState }) => {
  const [messages, setMessages] = React.useState([])
  React.useEffect(() => {
    if (modalState) {
      const unsubscribe = db
        .collection('channels')
        .doc(channel)
        .collection('messages')
        .where('visibility', 'array-contains', 'admin')
        .orderBy('createdAt', 'desc')
        .onSnapshot(snap => {
          setMessages(snap.docs.map((doc) => {
            const msgObj = doc.data()

            return {
              ...msgObj,
              uid: doc.id
            }
          }))
        })
      return () => unsubscribe()
    }
  }, [modalState, channel])

  return (
    <>
      {messages ? (
        <ul className='messageList'>
          {messages.map((msg, index) => {
            const type = msg.type

            switch (type) {
              case 'text':
                return (
                  <li className={`message message__${type}`} key={index}>
                    <div className='messageContent'>
                      <img src={msg?.photoUrl ? msg.photoUrl : 'https://picsum.photos/200/200'} alt='user' className='message__avatar' />
                      <h3 className='messageSender'>
                        {msg.senderName} · <span>{dayjs.utc(msg.createdAt).local().fromNow()}</span>
                      </h3>

                      {(type === 'text' || msg.content.type === 'text') && <p>{msg.content.text}</p>}
                    </div>
                  </li>
                )

              case 'doc':
                return (
                  <li className={`message message__${type}`} key={index}>
                    <div className='messageContent'>
                      <img src={msg?.photoUrl ? msg.photoUrl : 'https://picsum.photos/200/200'} alt='user' className='message__avatar' />
                      <h3 className='messageSender'>
                        {msg.senderName} · <span>{dayjs.utc(msg.createdAt).local().fromNow()}</span>
                      </h3>
                      <div className='messageDocs'>
                        <div className='docWrapper'>
                          <div className='content'>
                            {/* <div className='platform'>YouTube</div> */}
                            <div className='title'>{msg.content.text}</div>
                            <div
                              className='video'
                              style={{
                                backgroundImage: `url(${msg.content?.url})`
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                )
              case 'meeting':
                return (
                  <li className={`message message__${type}`} key={index}>
                    <div className='messageContent'>
                      <img src={msg?.photoUrl ? msg.photoUrl : 'https://picsum.photos/200/200'} alt='user' className='message__avatar' />
                      <h3 className='messageSender'>
                        {msg.senderName} · <span>{dayjs.utc(msg.createdAt).local().fromNow()}</span>
                      </h3>
                      <div className='messageDocs'>
                        <div className='docWrapper'>
                          <div className='content'>
                            <div className='platform'>{msg.content.event.title}:</div>
                            <a download={`${msg.content.event.title}.ics`} className='title' href={msg.content.event.attachment}>Add To Calendar</a>
                            {/* <div
                              className='video'
                              style={{
                                backgroundImage: `url(${msg.content?.url})`
                              }}
                            /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                )

              case 'log':
                return (
                  <li className={`message message__${type}`} key={index}>
                    {msg.content.text}
                  </li>
                )

              default:
                return null
            }
          })}
        </ul>
      ) : (
        <p>Loading...</p>
      )}
    </>
  )
}
