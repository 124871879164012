import React, { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { getAdminUser } from '../../constants/api'
import { useGlobalStateContext } from '../../constants/UseCtx'

// COMPONENTS
import { AuthContext } from '../../hooks/useAuth'
import { EditAdmin } from '../admin/EditAdmin'
import { SidebarShortcut } from './SidebarShortcut'
import { Modal } from '../shared/layout/Modal'
import { CompanyWidget } from './CompanyWidget'

export const ProfileCard = () => {
  // STATE
  const [open, setOpen] = useState(false)
  const [ctxItem, setCtxItem] = useState(null)

  const { currentMenu, ctxMenu } = useGlobalStateContext()
  const { currentUser } = useContext(AuthContext)

  const { data: user, isLoading, isError } = useQuery('user', getAdminUser, { staleTime: 1000 * 60 * 30, enabled: !!currentUser })

  useEffect(() => {
    setCtxItem(JSON.parse(ctxMenu))
  }, [ctxMenu])

  const openModal = () => {
    setOpen(true)
    document.querySelector('body').style.overflow = 'hidden'
    document.querySelector('body').style.height = '100vh'
  }

  const closeModal = () => {
    setOpen(false)
    document.querySelector('body').style.overflow = 'initial'
  }

  if (!user || currentMenu === 'closed' || isLoading || isError) return null

  return (
    <>
      <Modal
        open={open}
        openModal={openModal}
        closeModal={closeModal}
        customInner
      >
        <EditAdmin user={user} closeModal={closeModal} />
      </Modal>
      <div className='profileCard-container'>
        <div className='profileSection' onClick={() => openModal()}>
          <h3>
            <span>Hello,</span>
            <br />
            {user?.data?.fullName}
          </h3>
          <img src={user.data?.photoUrl} alt='user' className='profileAvatar' />
        </div>
        <CompanyWidget user={user.data} />
        <div className='horizontalDivider' />
        {ctxItem && (
          <div className='ctx__area'>
            {ctxItem.key === 'jobs' && ctxItem.data.length > 0
              ? (
                <>
                  <p className='footer__title'>Jobs you manage</p>

                  {ctxItem.data.map(job => (
                    <SidebarShortcut caption={job.adminType} key={job.uid} href={`jobs/${job.uid}`} state={job} />
                  ))}
                </>
              )
              : ctxItem.key === 'locations' && ctxItem.data.length > 0
                ? (
                  <>
                    <p className='footer__title'>Locations you manage</p>
                    {ctxItem.data.map(loc => (
                      <SidebarShortcut key={loc.uid} href={`locations/${loc.uid}`} state={loc} />

                    ))}
                  </>
                ) : null}
          </div>
        )}

        {/* <button className='btnPrimary' onClick={() => dispatch({ type: 'TOGGLE_MENU', theme: 'closed' }) & auth.signOut()}>Sign Out</button> */}
      </div>
    </>
  )
}
