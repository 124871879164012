import dayjs from 'dayjs'
import React from 'react'

export const CompanyCard = ({ company }) => {
  return (
    <div className='company__card'>
      <img src={company.photoUrl} alt='company' className='company__image' />
      <div className='company__content'>
        <h3 className='company__title'>{company.title}</h3>

        <div className='company__locationCount'>Locations: {company.locations.length}</div>
        <div className='compant__date'>Created On: {dayjs(company.createdAt).format('DD/MM/YYYY')}</div>
      </div>
    </div>
  )
}
