import React, { useContext, useMemo, useState } from 'react'

// Packages
import { useLocation, useParams, useHistory } from 'react-router-dom'

// Components
import { Job } from '../jobs/Job'

// Icons
import { ReactComponent as MoreIcon } from '../shared/icons/more.svg'
import { ReactComponent as PlusIcon } from '../shared/icons/plus.svg'
import { ReactComponent as PenIcon } from '../shared/icons/pen.svg'
import { ReactComponent as ChevronIcon } from '../shared/icons/chevron.svg'
import { ReactComponent as ArrowIcon } from '../shared/icons/arrow.svg'
import { ReactComponent as DeleteIcon } from '../shared/icons/delete.svg'

import { useMutation, useQuery, useQueryClient } from 'react-query'
import { createLocationAdmin, getCompanyAdmins, deleteLocationAdmin, deleteLocation, getAdminUser } from '../../constants/api'
import { Dropdown } from '../shared/layout/Dropdown'
import { Loader } from '../shared/Loader'
import { useAlert } from '../../hooks/useAlert'
import { Alert } from '../shared/Alert'
import { setEdjs } from '../../hooks/setEdjs'
import { useSingleLocation } from '../../hooks/useLocations'
import { Location } from './Location'
import { AuthContext } from '../../hooks/useAuth'

export const SelectedLocation = () => {
  const [open, setOpen] = useState(false)
  // eslint-disable-next-line
  const [location, setLocation] = useState(useLocation().state)
  const [adminToUpdateUid, setAdminToUpdateUid] = useState('')

  const { slug: locationUid } = useParams()
  const queryClient = useQueryClient()
  const { isActive, message, openAlert } = useAlert()
  const history = useHistory()
  const { currentUser } = useContext(AuthContext)

  // API INTERACTION
  const { data: user, isLoading: userLoading, isError: userError } = useQuery('user', getAdminUser, { staleTime: 1000 * 60 * 30, enabled: !!currentUser })
  const locationQuery = useSingleLocation(locationUid)
  const { data: admins, isLoading: adminsLoading } = useQuery('admins', getCompanyAdmins, { staleTime: 1000 * 60 * 5, refetchOnMount: false })
  const { mutateAsync: createLocationAdminAsync, isLoading: createAdminLoading } = useMutation(createLocationAdmin, {
    onSuccess: () => {
      openAlert('Success')
      queryClient.invalidateQueries('admins')
      queryClient.invalidateQueries('location')
    },
    onError: (e) => openAlert(e.response.data.message),
    onSettled: () => setAdminToUpdateUid('')
  })
  const { mutateAsync: deleteAdmin } = useMutation(deleteLocationAdmin, {
    onSuccess: () => {
      openAlert('Success')
      queryClient.invalidateQueries('admins')
      queryClient.invalidateQueries('location')
    },
    onError: (e) => openAlert(e.response.data.message)
  })

  const { mutateAsync: asnycLocationDelete } = useMutation('deleteJob', deleteLocation, {
    onSuccess: () => {
      queryClient.invalidateQueries('locations')
      history.push('/locations')
    }
  })

  const onDelete = async () => {
    await asnycLocationDelete(locationQuery.data.uid)
  }

  console.log(locationQuery.data)

  const determinedAdmins = useMemo(() => {
    if (!adminsLoading && !locationQuery.isLoading && admins && locationQuery.data) {
      let potentialAdmins = []
      potentialAdmins = admins.data.filter(admin => {
        if (locationQuery.data?.createdByUid === admin.uid) return false
        else if (locationQuery.data.locationAdmins.findIndex((jAdmin) => {
          return admin.uid === jAdmin.adminUid
        }) > -1) return false
        else return true
      })
      return {
        potential: potentialAdmins
      }
    }
  }, [admins, adminsLoading, locationQuery.data, locationQuery.isLoading])

  const onAdmin = async (role, adminUid) => {
    setAdminToUpdateUid(adminUid)
    await createLocationAdminAsync({
      locationUid,
      adminUid,
      role
    })
  }

  return (
    <>
      {!locationQuery.isLoading && locationQuery.data
        ? (
          <>
            <Alert isActive={isActive} message={message} />
            <div style={{ justifyContent: 'flex-end' }} className='flex'>
              <Dropdown open={open} setOpen={setOpen} icon={<MoreIcon />}>
                <Dropdown.Menu name='main'>
                  <Dropdown.Item rightIcon={<ChevronIcon />} leftIcon={<PlusIcon />} goToMenu='createAdmins'>Create Admin</Dropdown.Item>
                  <Dropdown.Item goToMenu='admins' rightIcon={<ChevronIcon />} leftIcon={<PlusIcon />}>Admins</Dropdown.Item>
                  <Dropdown.Link leftIcon={<PenIcon />} href={`/locations/${locationUid}/edit`} state={location}>Edit</Dropdown.Link>
                  {!userLoading && !userError && user && (
                    <>
                      {(user.data.role === 'superAdmin' || user.data.role === 'admin' || locationQuery.data.locationAdmins.find(x => (x.adminUid === user.data.uid && x.role === 'editor'))) && (
                        <Dropdown.Item callback={onDelete} leftIcon={<DeleteIcon />}>Delete</Dropdown.Item>
                      )}
                    </>
                  )}
                </Dropdown.Menu>
                <Dropdown.Menu customWidth='350px' isSecondary name='createAdmins'>
                  <Dropdown.Item marginBottom goToMenu='main' leftIcon={<ArrowIcon />}>
                    <h2>Company Admins</h2>
                  </Dropdown.Item>
                  {adminsLoading
                    ? <Loader />
                    : determinedAdmins !== undefined ? (
                  determinedAdmins.potential?.map(admin => (
                    <Dropdown.Item image={admin?.photoUrl} loading={createAdminLoading && adminToUpdateUid === admin?.uid && true} actions={[{ title: 'Editor', action: () => onAdmin('editor', admin?.uid) }, { title: 'Viewer', action: () => onAdmin('editor', admin?.uid) }]} key={admin?.uid}>
                      {admin?.fullName}
                      <Dropdown.Caption>Company: {admin.role}</Dropdown.Caption>
                    </Dropdown.Item>
                  ))
                    ) : <div>No Admins</div>}
                </Dropdown.Menu>
                <Dropdown.Menu customWidth='350px' isSecondary name='admins'>
                  <Dropdown.Item marginBottom goToMenu='main' leftIcon={<ArrowIcon />}>
                    <h2>Admins</h2>
                  </Dropdown.Item>
                  {locationQuery.isLoading
                    ? <Loader />
                    : locationQuery.data && locationQuery.data.locationAdmins.length > 0
                      ? (
                        locationQuery.data.locationAdmins.map(admin => (
                          <Dropdown.Item image={admin.admin?.photoUrl} rightIcon={<DeleteIcon />} callback={async () => await deleteAdmin({ adminUid: admin?.adminUid, locationUid: admin?.locationUid })} key={admin.admin?.uid}>
                            {admin.admin?.fullName}
                            <Dropdown.Caption>{admin?.role}</Dropdown.Caption>
                          </Dropdown.Item>
                        ))
                      )
                      : <div>No Admins</div>}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <details style={{ marginTop: '-3.5rem' }} open>
              <summary style={{ fontSize: '1.5rem', padding: '1.5rem 0 1.5rem 0', fontWeight: '700' }}>
                Location Details
              </summary>
              <div className='previewCard'>
                <div onClick={() => setOpen(!open)} className='icon-button icon-button--hidden'><PlusIcon /></div>
                <div className='previewSummary'>
                  <div style={{ width: 'calc(50% - 2em)', overflow: 'hidden', borderRadius: '.9em' }}>
                    <img src={locationQuery.data.photoUrl} className='previewMedia' alt='location' />
                  </div>
                  <div className='previewMeta'>
                    <h2>{locationQuery.data.title}</h2>
                    <p>Total Jobs:</p>
                    <h3 className='statBlock statBlock--jobs'>{locationQuery.data.jobs.length}</h3>

                    {/* <p>Total Diggs:</p> */}
                    {/* <h3 className='statBlock statBlock--jobs'>{location.meta.diggs ? location.meta.diggs : 0}</h3> */}
                  </div>
                </div>
                <div className='horizontalDivider' />
                {locationQuery.data && !locationQuery.isLoading && <div className='previewDesc bioCard editor' dangerouslySetInnerHTML={{ __html: setEdjs(locationQuery.data.descriptionAsJSON.blocks) }} />}
              </div>
            </details>

            {locationQuery.data.jobs.length > 0 && (
              <details>
                <summary style={{ fontSize: '1.5rem', padding: '1.5rem 0 1.5rem 0', fontWeight: '700' }}>Jobs</summary>
                {locationQuery.data.jobs.map((job, index) => {
                  return <Job job={job} key={index} to={`/jobs/${job.uid}`} />
                })}
              </details>

            )}

          </>
        ) : (
          <>
            <Location loading={locationQuery.isLoading} />
          </>
        )}
    </>
  )
}
