import React, { createContext, useContext, useReducer } from 'react'

// Define Conext
const GlobalStateContext = createContext()
const GlobalDispatchContext = createContext()

// Reducer
const globalReducer = (state, action) => {
  switch (action.type) {
    case 'TOGGLE_MENU': {
      return {
        ...state,
        currentMenu: action.theme
      }
    }
    case 'CTX_MENU': {
      return {
        ...state,
        ctxMenu: action.data
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(globalReducer, {
    currentMenu: window.localStorage.getItem('menuStatus') === null ? 'open' : window.localStorage.getItem('menuStatus'),
    ctxMenu: window.localStorage.getItem('ctxMenu') === null ? null : window.localStorage.getItem('ctxMenu')
  })

  return (
    <GlobalDispatchContext.Provider value={dispatch}>
      <GlobalStateContext.Provider value={state}>
        {children}
      </GlobalStateContext.Provider>
    </GlobalDispatchContext.Provider>
  )
}

// custom hooks for when we want to use our global state
export const useGlobalStateContext = () => useContext(GlobalStateContext)

export const useGlobalDispatchContext = () => useContext(GlobalDispatchContext)
