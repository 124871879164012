import { useEffect, useState } from 'react'

export const useKeyDown = (targetKey) => {
  const [keyPressed, setKeyPressed] = useState(false)

  function downHandler ({ key }) {
    if (key === targetKey) {
      setKeyPressed(true)
    }
  }

  function upHandler ({ key }) {
    if (key === targetKey) {
      setKeyPressed(false)
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)
    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
    }
    // eslint-disable-next-line 
  }, [])
  return keyPressed
}
