import React, { useContext } from 'react'
import { QueryClient } from 'react-query'

// Package Imports
import { Redirect, Route } from 'react-router-dom'
import { getAdminUser, getJobs, getLocations } from '../../constants/api'
import { AuthContext } from '../../hooks/useAuth'

export const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
  const { currentUser } = useContext(AuthContext)
  let url = '/login'
  if (window.location.pathname !== '/') {
    url = url + `?returnUrl=${window.location.pathname}`
  }
  // PREFETCH QUERIES
  const queryClient = new QueryClient()
  if (currentUser) {
    queryClient.prefetchQuery('user', getAdminUser, { staleTime: 1000 * 60 * 15 })
    queryClient.prefetchQuery('jobs', getJobs, { staleTime: 1000 * 60 * 5 })
    queryClient.prefetchQuery('locations', getLocations, { staleTime: 1000 * 60 * 5 })
  }

  return (
    // <Route
    //   {...rest}
    //   render={routeProps =>
    //     currentUser ? (
    //       <RouteComponent {...routeProps} />
    //     ) : <RouteComponent {...routeProps} />}
    // />
    <Route
      {...rest}
      render={routeProps =>
        currentUser ? (
          <RouteComponent {...routeProps} />
        )
          : <Redirect to={url} />}
    />
  )
}
