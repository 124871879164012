import React from 'react'
import { Link } from 'react-router-dom'

export const SidebarShortcut = ({ state, href, caption }) => {
  return (
    <Link
      to={{
        pathname: href,
        state: state
      }} className='jobShortcut'
    >
      <div>
        <p className='content'>{state.title}</p>
        {caption && <p className='caption'>{caption}</p>}
      </div>
    </Link>
  )
}
