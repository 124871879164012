import axios from 'axios'
import { useQuery, useQueryClient } from 'react-query'
import { getJobs, AUTH_HEADER } from '../constants/api'

export const useJobs = () => {
  return useQuery(
    'jobs',
    getJobs,
    {
      staleTime: 1000 * 60 * 30,
      refetchOnWindowFocus: true
    }
  )
}

export const useJob = (jobUid) => {
  const queryClient = useQueryClient()

  return useQuery(
    ['job', jobUid],
    async () => axios.get(
      `${process.env.REACT_APP_API_URL}/jobs/${jobUid}`,
      {
        headers: {
          Authorization: await AUTH_HEADER()
        }
      }
    ).then(res => res.data.data),
    {
      initialData: () => {
        if (jobUid) {
          const cachedJobs = queryClient.getQueryData('jobs')
          if (cachedJobs !== undefined && Array.isArray(cachedJobs)) return cachedJobs?.find(x => x.uid === jobUid)
          else if (cachedJobs !== undefined && typeof cachedJobs === 'object') return cachedJobs
        }
      },
      initialStale: true,
      enabled: !!jobUid
    }
  )
}

