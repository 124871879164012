import React from 'react'

export const CompanyWidget = ({ user }) => {
  return (
    <>
      <p className='footer__title'>Your Company:</p>

      <div className='company__widget'>
        <div className='widget__header'>
          <img src={user.company.photoUrl} alt='company' className='header__img' />
          <h3 className='widget__title'>{user.company.title}</h3>
        </div>

        <p className='footer__title'>Company Statistics</p>
        <div className='footer__wrapper'>
          <div className='statContainer'>
            <p>Total Jobs:</p>
            <h3 className='statBlock statBlock--jobs'>0</h3>
          </div>
          <div className='statContainer'>
            <p>Total Views:</p>
            <h3 className='statBlock statBlock--views'>0</h3>
          </div>
          <div className='statContainer'>
            <p>Total Diggs:</p>
            <h3 className='statBlock statBlock--diggs'>0</h3>
          </div>
        </div>
      </div>
    </>
  )
}
