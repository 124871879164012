import { Field } from 'formik'
import React from 'react'
import { Input } from '../shared/forms/Input'

export const CreateDetails = ({ locationToEdit, data }) => {
  // const [maxSalary, setMaxSalary] = React.useState('')
  // const [minSalary, setMinSalary] = React.useState('')

  // const formatCurrency = (num, type) => {
  //   if (type === 'max') {
  //     setMaxSalary(new Intl.NumberFormat('en-IN').format(num.replace(/[^\d.-]/g, '')))
  //   } else {
  //     setMinSalary(new Intl.NumberFormat('en-IN').format(num.replace(/[^\d.-]/g, '')))
  //   }
  // }
  return (
    <div className='requirementsCard'>
      <div className='formContainer'>
        <div className='formGroup inline'>
          <label className='bold' htmlFor='jobTitle'>Job Title</label>
          <Input
            name='jobTitle'
            placeholder='Job Title'
            label='JobTitle'
            required
          />
        </div>

        <div className='formGroup inline'>
          <label className='bold' htmlFor='location'>Location</label>
          <Field
            placeholder='Location'
            name='location'
            as='select'
          >
            <option value={null}>Select a location...</option>
            {data ? data.map(opt => (
              <option key={opt.uid} value={opt.uid}>{opt.title}</option>
            )) : (
              <option>Loading...</option>
            )}
          </Field>
        </div>

        <div className='formGroup inline'>
          <label className='bold'>Job Type</label>
          <div className='toggle'>
            <Field type='radio' name='jobType' value='fullTime' id='sizeWeight' checked />
            <label htmlFor='sizeWeight'>Full Time</label>

            <Field type='radio' name='jobType' value='partTime' id='test2' />
            <label htmlFor='test2'>Part Time</label>
          </div>
        </div>

        <div className='salaryContainer'>
          <div className='formGroup inline'>
            <label className='bold' htmlFor='minSalary'>Minimum Salary</label>
            <Input
              placeholder='Min Salary'
              name='minSalary'
              type='number'
              required
              label='MinSalary'
              // value={minSalary}
              // onInput={e => formatCurrency(e.target.value, 'min')}
            />
          </div>

          <div className='formGroup inline'>
            <label className='bold' htmlFor='minSalary'>Maximum Salary</label>
            <Input
              placeholder='Max Salary'
              name='maxSalary'
              type='number'
              required
              label='MaxSalary'
              // value={maxSalary}
              // onInput={e => formatCurrency(e.target.value, 'max')}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
