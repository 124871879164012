import React, { useMemo } from 'react'

import { Link } from 'react-router-dom'
import { Location } from './Location'
import { ReactComponent as PlusIcon } from '../shared/icons/plus.svg'
import { UID } from '../../constants/api'
import { useGlobalDispatchContext } from '../../constants/UseCtx'
import { useAllLocations } from '../../hooks/useLocations'

export const Locations = () => {
  const dispatch = useGlobalDispatchContext()
  const locationsQuery = useAllLocations()

  useMemo(() => {
    const determineAdmins = async () => {
      const uid = await UID()
      let createdBy = []

      if (uid && !locationsQuery.isLoading && locationsQuery.data) {
        // eslint-disable-next-line
        createdBy = locationsQuery.data.filter(loc => {
          if (loc.createdByUid === uid) return true
          else if (loc.locationAdmins.findIndex(locAdmin => {
            return locAdmin.adminUid === uid
          }) > -1) return true
        })
      }
      dispatch({ type: 'CTX_MENU', data: JSON.stringify({ key: 'locations', data: createdBy }) })
    }
    determineAdmins()
    return () => dispatch({ type: 'CTX_MENU', data: null })
  }, [locationsQuery.data, locationsQuery.isLoading, dispatch])

  if (locationsQuery.isLoading) {
    return (
      <>
        <Location loading={locationsQuery.isLoading} />
      </>
    )
  }

  return (
    <>
      {locationsQuery.data.length > 0 ? locationsQuery.data.map((loc, index) => {
        return (
          <Link
            key={loc.uid}
            to={{
              pathname: `/locations/${loc.uid}`,
              state: loc
            }}
          >
            <Location location={loc} />
          </Link>
        )
      }) : (
        <Link
          className='jobCard outlined'
          to='/location/create'
        >
          <div className='job__feedback'>
            <PlusIcon />
            <h3>No locations yet!</h3>
            <p>Build a location to start your Digger experience</p>
          </div>
        </Link>
      )}
    </>
  )
}
