import React, { useMemo, useState } from 'react'

// Packages
import { FieldArray } from 'formik'
import { Alert } from '../shared/Alert'
import { useAlert } from '../../hooks/useAlert'
import { searchSkills } from '../../constants/api'

// Icons
import { ReactComponent as PlusIcon } from '../shared/icons/plus.svg'
import useDebounce from '../../hooks/useDebounce'
import { Loader } from '../shared/Loader'
import { uuidv4 } from '../../hooks/utils/useUuid'

export const CreateExperience = ({ isEditing, values, deletedSkills, setDeletedSkills, jobSkills, setJobSkills }) => {
  const { isActive, message, openAlert } = useAlert()
  const [currIndex, setCurrIndex] = useState(null)
  const [input, setInput] = useState(null)
  const [loading, setLoading] = useState(false)
  const debouncedInput = useDebounce(input, 500)
  const noSkillMsg = 'Tap here so we can dig a little deeper for your skill'

  useMemo(async () => {
    if (debouncedInput) {
      setLoading(true)
      const list = document.getElementById(`skills-${currIndex}`)
      list.innerHTML = ''

      const newOptions = []

      const data = await searchSkills(debouncedInput)
      if (data && data.length > 0) {
        const formattedSkills = data.map(skill => ({
          label: skill.name,
          key: skill.uid,
          skillUid: skill.uid
        }))
        formattedSkills.forEach(skill => {
          newOptions.push(`<option data-skill-uid="${skill.skillUid}">${skill.label}</option>`)
        })
      } else {
        newOptions.push(`<option value="Tap here so we can dig a little deeper for your skill">${debouncedInput}</option>`)
      }
      list.innerHTML = newOptions.join('')
      if (data && data.length > 0) {
        findValueInOptions(document.querySelectorAll(`#skills-${currIndex} option`), debouncedInput, currIndex)
      }
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [debouncedInput])

  const onSkillChange = async (e, index) => {
    setCurrIndex(index)

    const input = e.target
    const list = input.getAttribute('list')
    const options = document.querySelectorAll(`#${list} option`)

    if (input.value === noSkillMsg) {
      const savedInput = input
      input.value = 'Loading...'
      setLoading(true)

      const newOptions = []
      const data = await searchSkills(savedInput, true)

      if (data && data.length > 0) {
        const formattedSkills = data.map(skill => ({
          label: skill.name,
          key: skill.uid,
          skillUid: skill.uid
        }))

        formattedSkills.forEach(skill => {
          newOptions.push(`<option data-skill-uid="${skill.skillUid}">${skill.label}</option>`)
        })
        input.value = savedInput
        setLoading(false)
      } else {
        setLoading(false)
        jobSkills[index] = null
        setJobSkills(jobSkills)
        newOptions.push(`<option>We couldn't find ${savedInput}, please try another</option>`)
        // input.value = "Sorry, we couldn't found that skill"
      }
      document.getElementById(`skills-${index}`).innerHTML = newOptions.join('')
    } else if (input.value.length > 2) {
      // setInput(input.value)
      if (options.length === 0) setInput(input.value)
      else {
        if (input.value !== noSkillMsg) findValueInOptions(options, input.value, index)
      }
    }
  }

  const findValueInOptions = function (options, value, stateIndex) {
    let foundValue = false
    for (let index = 0; index < options.length; index++) {
      if (options[index].innerText === value) {
        foundValue = true
        const objAtIndex = jobSkills[stateIndex]
        jobSkills[stateIndex] = { ...objAtIndex, skillUid: options[index].getAttribute('data-skill-uid'), name: options[index].innerText }
        setJobSkills(jobSkills)
        break
      }
    }
    if (!foundValue) {
      setInput(value)
      jobSkills[stateIndex] = null
      setJobSkills(jobSkills)
    }
  }

  const removeSkill = (i, item) => {
    jobSkills.splice(i, 1)
    setJobSkills(jobSkills)

    if (item?.disabled) {
      setDeletedSkills([...deletedSkills, item])
    }
  }

  const validateSkill = (arr, push) => {
    if (arr.length > 0) {
      const latestSkill = jobSkills[arr.length - 1]
      if (!latestSkill || !latestSkill?.name || !latestSkill?.skillUid) openAlert('Please select a skill', 'Error')
      else if (!latestSkill?.experience) openAlert('Please add experience', 'Error')
      else {
        setJobSkills([...jobSkills, null])
        push({
          id: uuidv4(),
          name: '',
          experience: 0
        })
      }
    } else {
      setJobSkills([...jobSkills, null])
      push({
        id: uuidv4(),
        name: '',
        experience: 0
      })
    }
  }

  return (
    <div className={values.experience.length < 1 ? 'requirementsCard outlined' : 'requirementsCard'}>
      <Alert isActive={isActive} message={message} />
      <FieldArray name='experience'>
        {({ push, remove }) => (
          <>
            {values.experience.length > 0 ? values.experience.map((p, index) => {
              return (
                <div key={p.uid} className='formContainer'>
                  <div className='formGroup inline'>
                    <input
                      placeholder={p.disabled ? p.name : 'Select an field of experience'}
                      className='skillInput'
                      onChange={e => onSkillChange(e, index)}
                      list={`skills-${index}`}
                      name={`experience[${index}].skill`}
                      id={`skill-${index}`}
                      disabled={p.disabled}
                      readOnly={p.disabled}
                    />
                    {loading && (
                      <div className='skill__loader'>
                        <Loader />
                      </div>
                    )}
                    <datalist id={`skills-${index}`} />
                  </div>
                  <div className='formGroup formGroup--withBtn inline'>
                    <input
                      placeholder={p.disabled ? p.experience : 'Select an field of experience'}
                      name={`experience[${index}].experience`}
                      type='number'
                      onChange={e => {
                        const copiedSkills = jobSkills
                        const objAtIndex = jobSkills[index]

                        copiedSkills[index] = { ...objAtIndex, experience: e.target.value }
                        setJobSkills(copiedSkills)
                      }}
                    />
                    <button type='button' className='btnPrimary remove' onClick={() => removeSkill(index, p)}>x</button>
                  </div>
                </div>
              )
            }) : (
              <div
                className='card__content'
                onClick={() => validateSkill(values.experience, push)}
              >
                <PlusIcon />
                <h3>Click here to start addings skills</h3>
                <p>You are required to add at least one skill.</p>
              </div>
            )}
            {values.experience.length > 0 && (
              <button
                type='button'
                className='btnPrimary'
                onClick={() => validateSkill(values.experience, push, currIndex)}
              >
                Add
              </button>
            )}
          </>
        )}
      </FieldArray>
    </div>
  )
}
