import axios from 'axios'
import { useQuery, useQueryClient } from 'react-query'
import { AUTH_HEADER } from '../constants/api'

export const useCompanies = () => {
  return useQuery(
    'companies',
    async () => axios.get(
      `${process.env.REACT_APP_API_URL}/companies?all=true`,
      {
        headers: {
          Authorization: await AUTH_HEADER()
        }
      }
    ).then(res => res.data.data),
    {
      staleTime: 1000 * 60 * 30,
      refetchOnWindowFocus: true
    }
  )
}

export const useCompany = (companyUid) => {
  const queryClient = useQueryClient()

  return useQuery(
    ['company', companyUid],
    async () => axios.get(
      `${process.env.REACT_APP_API_URL}/companies/${companyUid}`,
      {
        headers: {
          Authorization: await AUTH_HEADER()
        }
      }
    ).then(res => res.data.data),
    {
      initialData: () => {
        if (companyUid) {
          const cachedCompanies = queryClient.getQueryData('companies')
          if (cachedCompanies !== undefined && Array.isArray(cachedCompanies)) return cachedCompanies?.find(x => x.uid === companyUid)
          else if (cachedCompanies !== undefined && typeof cachedCompanies === 'object') return cachedCompanies
        }
      },
      initialStale: true,
      enabled: !!companyUid
    }
  )
}

