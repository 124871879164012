import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import { ReactComponent as CloseIcon } from '../icons/close.svg'
import { Loader } from '../Loader'

export const Dropdown = ({ icon, open, setOpen, children, above }) => {
  const [menuHeight, setMenuHeight] = React.useState(null)
  const [activeMenu, setActiveMenu] = React.useState('main')
  const dropdownRef = React.useRef(null)

  useEffect(() => {
    setMenuHeight(dropdownRef.current?.firstChild?.offsetHeight)
  }, [])

  function calcHeight (el) {
    const height = el.offsetHeight
    setMenuHeight(height)
  }

  return (
    <div className='claim-item'>

      <button className='icon-button icon-button--nav' onClick={() => setOpen(!open)}>
        {!open ? icon : <CloseIcon onClick={() => setOpen(!open)} />}
      </button>

      {open && (
        <div className={above ? 'dropdown above' : 'dropdown'} style={{ height: menuHeight }} ref={dropdownRef}>
          {React.Children.map(children, (child, i) => {
            return React.cloneElement(child, { activeMenu, dropdownRef, setActiveMenu, calcHeight })
          })}
        </div>
      )}
    </div>
  )
}

Dropdown.Item = function Item ({ children, image = null, marginBottom = '0rem', loading = false, goToMenu, leftIcon, actions = null, rightIcon, setActiveMenu, callback = null }) {
  return (
    <div
      style={{ marginBottom }}
      className='drop-item' onClick={() => goToMenu && !callback ? setActiveMenu(goToMenu) : callback && !goToMenu ? callback() : null}
    >
      <span className='icon-button' style={image && { backgroundImage: `url(${image})`, backgroundPosition: 'center', backgroundSize: 'cover' }}>{leftIcon}</span>
      <div className='drop-content'>{children}</div>
      {!loading && actions && (
        <div className='drop-actions'>
          {actions.map(action => (
            <p key={action.title} onClick={() => action.action()}>{action.title}</p>
          ))}
        </div>
      )}

      {loading && <Loader />}

      {rightIcon && !actions && (<span className='icon-right icon-right--small'>{rightIcon}</span>)}
    </div>
  )
}

Dropdown.Caption = function Caption ({ children }) {
  return (
    <span className='drop-item__caption'>{children}</span>
  )
}

Dropdown.Link = function dLink ({ children, href, state, leftIcon, rightIcon }) {
  return (
    <Link
      to={{
        pathname: href,
        state
      }}
      className='drop-item'
    >
      <span className='icon-button'>{leftIcon}</span>
      {children}
      <span className='icon-right'>{rightIcon}</span>
    </Link>
  )
}

Dropdown.Menu = function Menu ({ children, name, dropdownRef, activeMenu, customWidth = null, isSecondary, setActiveMenu, calcHeight }) {
  // if (customWidth) dropdownRef.style.width = customWidth
  useEffect(() => {
    if (customWidth && dropdownRef !== undefined) {
      // console.log(dropdownRef)
      dropdownRef.current.style.width = customWidth
    }
  }, [dropdownRef, customWidth])
  return (
    <CSSTransition
      in={activeMenu === name}
      timeout={500}
      classNames={isSecondary ? 'menu-secondary' : 'menu-primary'}
      unmountOnExit
      onEnter={calcHeight}
    >
      <div className='menu'>
        {
          React.Children.map(children, (child, i) => {
            return React.cloneElement(child, { setActiveMenu })
          })
        }

      </div>
    </CSSTransition>
  )
}
