import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/firestore'

const firebaseApp = firebase.initializeApp({
  apiKey: 'AIzaSyCF5krburHH63ROqOc4tiM9k8RCy-fw8zg',
  authDomain: 'diggerapp-api.firebaseapp.com',
  projectId: 'diggerapp-api',
  storageBucket: 'diggerapp-api.appspot.com',
  messagingSenderId: '574010983407',
  appId: '1:574010983407:web:2dc7292f57c689538e2c1a',
  measurementId: 'G-S1FHYNMY2M'
})

const db = firebaseApp.firestore()
const auth = firebase.auth()
const storage = firebase.storage()
const provider = new firebase.auth.GoogleAuthProvider()

export { auth, provider, storage }
export default db
