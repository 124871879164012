import React from 'react'
import { Link } from 'react-router-dom'
import { useCompanies } from '../../hooks/useCompanies'
import { Job } from '../jobs/Job'
import { CompanyCard } from './CompanyCard'

export const CompaniesWrapper = () => {
  const companiesQuery = useCompanies()

  if (companiesQuery.isLoading) {
    return (
      <div className='companies__wrapper'>
        <Job loading />
        <Job loading />
        <Job loading />
        <Job loading />
      </div>
    )
  }

  return (
    <div className='companies__wrapper'>
      {!companiesQuery.isLoading && companiesQuery.data.map(company => (
        <Link
          key={company.uid} to={{
            pathname: `/companies/${company.uid}`,
            state: company
          }}
        >
          <CompanyCard company={company} />
        </Link>
      ))}
    </div>
  )
}
