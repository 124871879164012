import React from 'react'

// Components
import { Applicant } from './Applicant'
// import { AutoForm } from '../../helpers/AutoForm'

// Packages
import { Draggable, Droppable } from 'react-beautiful-dnd'

export const Applicants = ({ appList, id, jobUid }) => {
  return (
    <div className='applicantListContainer'>
      <h3>Applicants</h3>
      <div className='appCard appCard--filterable'>
        <Droppable droppableId={id} key='applicants'>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {appList.map((app, index) => (
                <Draggable
                  key={app.uid}
                  draggableId={app.uid}
                  index={index}
                >
                  {(provided, snapshot) => {
                    return (
                      <div
                        ref={provided.innerRef}
                        {...provided.dragHandleProps}
                        {...provided.draggableProps}
                        style={{
                          userSelect: 'none',
                          ...provided.draggableProps.style
                        }}
                      >
                        <Applicant jobUid={jobUid} applicant={app} />
                      </div>
                    )
                  }}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </div>
  )
}
