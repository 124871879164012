import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { updateAdmin } from '../../constants/api'
import { storage } from '../../firebase'
import { useAlert } from '../../hooks/useAlert'
import { Alert } from '../shared/Alert'
import { Input } from '../shared/forms/Input'

import { ReactComponent as ArrowIcon } from '../shared/icons/arrow.svg'
import { ReactComponent as UploadIcon } from '../shared/icons/upload.svg'
import { Loader } from '../shared/Loader'

export const EditAdmin = ({ closeModal, user }) => {
  const [loading, setLoading] = useState(false)
  const [image, setImage] = useState(null)

  const queryClient = useQueryClient()
  const { isActive, message, openAlert } = useAlert()
  const { mutateAsync } = useMutation(updateAdmin, {
    onSuccess: () => {
      openAlert('Successfully updated profile')
      queryClient.invalidateQueries('user')
      closeModal()
    },
    onError: e => {
      openAlert(e.response.data.message)
    }
  })

  const uploadImage = (file) => {
    setLoading(true)
    const fileToUpload = storage.ref(`/candidateMedia/${file.name}`)
    fileToUpload
      .put(file)
      .then(snap => {
        fileToUpload
          .getDownloadURL()
          .then(url => {
            setImage({
              fileName: file.name,
              url
            })
            setLoading(false)
          })
      })
  }
  return (
    <div className='adminEdit--wrapper'>
      <Alert isActive={isActive} message={message} />
      <div onClick={() => closeModal()} className='icon-button'><ArrowIcon /></div>
      <h3>Edit your profile</h3>

      <Formik
        onSubmit={async (data, { resetForm }) => {
          const adminToUpdate = {
            fullName: data.fullName,
            email: data.email,
            photoUrl: image === null ? user.data.photoUrl : image.url,
            roleDescription: data.roleDescription
          }
          await mutateAsync({
            adminUid: user.data.uid,
            data: adminToUpdate
          })
        }}
        initialValues={{
          photoUrl: null,
          fullName: user.data.fullName,
          email: user.data.email,
          roleDescription: user.data.roleDescription
        }}
      >
        {({ values, errors, isSubmitting, dirty, isValid }) => (
          <Form>
            <label className='edit__image'>
              <div className='upload__icon'>
                {loading ? <Loader /> : <UploadIcon />}
              </div>
              <input type='file' name='photoUrl' style={{ display: 'none' }} onChange={(e) => uploadImage(e.currentTarget.files[0])} />
            </label>
            {image && (<p className='fileName'>{image.fileName}</p>)}
            <div className='formContainer'>
              <div className='formGroup inline noMargin'>
                <label className='bold' htmlFor='fullName'>Full Name</label>
                <Input
                  name='fullName'
                  label='JobTitle'
                />
              </div>
              <div className='formGroup inline noMargin'>
                <label className='bold' htmlFor='jobTitle'>Email</label>
                <Input
                  name='email'
                  required
                />
              </div>
              <div className='formGroup'>
                <label className='bold' htmlFor='jobTitle'>Role Description</label>
                <Input
                  name='roleDescription'
                  placeholder='E.g. HR Manager'
                  required
                />
              </div>
            </div>
            <button className='btnPrimary'>Update Profile</button>
          </Form>
        )}

      </Formik>
    </div>
  )
}
