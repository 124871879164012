import React, { useEffect } from 'react'

// Package Imports
import { BrowserRouter as Router, Route, Switch, Redirect, Link } from 'react-router-dom'

// COMPONENTS
import { Sidebar } from './components/navigation/Sidebar'
import { SearchBar } from './components/layout/SearchBar'
import { Jobs } from './components/jobs/Jobs'
import { Locations } from './components/locations/Locations'
import { ProfileCard } from './components/layout/ProfileCard'
import { CreateLocation } from './components/locations/CreateLocation'
import { CreateJob } from './components/jobs/CreateJob'
import { ShortlistManager } from './components/shortlist/ShortlistManager'
import { SelectedLocation } from './components/locations/SelectedLocation'
import { SelectedJob } from './components/jobs/SelectedJob'
import { PrivateRoute } from './components/auth/PrivateRoute'
import { Login } from './components/auth/Login'
import { useGlobalStateContext } from './constants/UseCtx'
import { CompaniesWrapper } from './components/companies/CompaniesWrapper'
import { CompanyCreate } from './components/companies/CompanyCreate'
import { CompanyDetails } from './components/companies/CompanyDetails'
import { LocationEdit } from './components/locations/LocationEdit'
import { CompanyEdit } from './components/companies/CompanyEdit'
import { EditJob } from './components/jobs/EditJob'
import ScrollToTop from './components/shared/ScrollToTop'
import { ResetPassword } from './components/auth/ResetPassword'

export const App = () => {
  const { currentMenu } = useGlobalStateContext()
  const { ctxMenu } = useGlobalStateContext()

  // HANDLING THE STATE OF MENUS
  useEffect(() => {
    window.localStorage.setItem('menuStatus', currentMenu)
    window.localStorage.setItem('ctxMenu', ctxMenu)
  }, [currentMenu, ctxMenu])

  // eslint-disable-next-line
  String.prototype.capitalize = function () {
    return this.charAt(0).toUpperCase() + this.slice(1)
  }

  return (
    <div className='appWrapper'>
      <Router>
        <Sidebar />
        <ScrollToTop>
          <div className={currentMenu === 'open' ? 'contentWrapper' : 'contentWrapper closed'}>
            <SearchBar />
            <Switch>
              <Route exact path='/login' component={Login} />
              <Route exact path='/forgot-password' component={ResetPassword} />
              {/* Job Routes */}
              <Route exact path='/'>
                <Redirect to='/jobs' />
              </Route>
              <PrivateRoute exact path='/jobs' component={Jobs} />
              <PrivateRoute exact path='/job/create' component={CreateJob} />
              <PrivateRoute exact path='/jobs/:slug' component={SelectedJob} />
              <PrivateRoute exact path='/jobs/:slug/edit' component={EditJob} />

              {/* Location Routes */}
              <PrivateRoute exact path='/locations' component={Locations} />
              <PrivateRoute exact path='/location/create' component={CreateLocation} />
              <PrivateRoute exact path='/locations/:slug' component={SelectedLocation} />
              <PrivateRoute exact path='/locations/:slug/edit' component={LocationEdit} />

              {/* Company Routes */}
              <PrivateRoute exact path='/companies' component={CompaniesWrapper} />
              <PrivateRoute exact path='/company/create' component={CompanyCreate} />
              <PrivateRoute exact path='/companies/:slug' component={CompanyDetails} />
              <PrivateRoute exact path='/companies/:slug/edit' component={CompanyEdit} />

              {/* Shortlist Routes */}
              <PrivateRoute exact path='/shortlist' component={ShortlistManager} />
              {/* <Route exact path='/shortlist/shared' component={SharedShortlist} /> */}

              {/* 404 Route */}
              <Route path='*'>
              There's no jobs here boet!
                <Link to='/jobs'>
                  <button className='btnPrimary'>Take the stairway to heaven</button>
                </Link>
              </Route>
            </Switch>
          </div>
        </ScrollToTop>
        <ProfileCard />
      </Router>
    </div>
  )
}
