import React from 'react'

export const ClaimButton = ({ icon, children }) => {
  const [open, setOpen] = React.useState(false)

  return (
    <div className='claim-item'>

      <div className='icon-button icon-button--nav' onClick={() => setOpen(!open)}>
        {icon}
      </div>

      {open && children}
    </div>
  )
}
