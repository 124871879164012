import React, { useEffect, useReducer, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { Modal, modalReducer } from '../shared/layout/Modal'

// Components
import { StatusPill } from '../shared/StatusPill'
import { ShortlistModal } from './ShortlistModal'

export const ShortlistItem = ({ applicant }) => {
  const { push } = useHistory()
  function useQuery () {
    return new URLSearchParams(useLocation().search)
  }
  const query = useQuery()
  const [modal, modalDispatch] = useReducer(modalReducer, { open: false })
  const [selectedShortlist] = useState(() => query.get('shortlist'))

  const handleOpenWithQueryParam = (name = 'name', payload = '') => {
    push(`${window.location.pathname}?${name}=${payload}`)
    modalDispatch({ type: 'open' })
  }
  const handleCloseWithQueryParam = () => {
    push(`${window.location.pathname}`)
    modalDispatch({ type: 'close' })
  }
  // eslint-disable-next-line
  useEffect(() => { if (selectedShortlist && selectedShortlist === applicant.candidate.uid) modalDispatch({ type: 'open' }) }, [selectedShortlist])

  return (
    <>
      <Modal
        open={modal.open}
        closeModal={() => modalDispatch({ type: 'close' })}
        customInner
        height='fit-content'
      >
        <ShortlistModal modalState={modal.open} person={applicant} handleModal={handleCloseWithQueryParam} />
      </Modal>
      <div
        className='applicantWrapper'
        onClick={() => handleOpenWithQueryParam('shortlist', applicant.candidate.uid)}
      >
        <div className='applicant'>
          {/* <input type='checkbox' /> */}
          <img src={applicant.candidate.photoUrl} alt='avatar' className='applicantAvatar' />
          <div className='applicantDetails'>
            <h3>{applicant.candidate.fullName}</h3>
            <p>{applicant.candidate.educationLevel}</p>
          </div>
          <div className='applicantActions'>
            <StatusPill type={applicant.status} message='Shortlisting' />
          </div>
        </div>
        <div className='horizontalDivider noMargin' />
      </div>
    </>
  )
}
