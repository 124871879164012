import React, { useEffect, useRef, useState } from 'react'

// Packages
import { Formik, Form, Field } from 'formik'
import { FileDropzone } from '../shared/forms/FileDropzone'
import { storage } from '../../firebase'
import * as yup from 'yup'
import { Input } from '../shared/forms/Input'
import { Loader } from '../shared/Loader'
import { useMutation, useQuery } from 'react-query'
import EditorJS from '@editorjs/editorjs'
import Header from '@editorjs/header'
import List from '@editorjs/list'
import ImageTool from '@editorjs/image'
import Embed from '@editorjs/embed'
import { createLocation, getAdminUser, getLocationTypes } from '../../constants/api'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { Alert } from '../shared/Alert'
import { useAlert } from '../../hooks/useAlert'
import { useLocation } from 'react-router-dom'

export const CreateLocation = () => {
  const [docs, setDocs] = useState(null)
  const [imageLoading, setImageLoading] = useState(false)
  const [dlUrl, setDlUrl] = useState(null)
  const [companyUid, setCompanyUid] = useState(null)
  const [companyTitle, setCompanyTitle] = useState(null)
  const [addressString, setAddressString] = useState(null)
  const [geo, setGeo] = useState(null)
  const [editor, setEditor] = useState(null)

  // CONSTANTS
  const { isActive, message, openAlert } = useAlert()
  function useQueryParam () {
    return new URLSearchParams(useLocation().search)
  }
  const params = useQueryParam()
  // console.log(params.get('uid')
  const titleFromParam = params.get('name')
  const uidFromParam = params.get('uid')

  // API INTERACTION
  const { data: user, isSuccess } = useQuery('user', getAdminUser, { staleTime: 1000 * 60 * 30, refetchOnMount: false })

  useEffect(() => {
    if (isSuccess) {
      if (uidFromParam && titleFromParam) {
        setCompanyUid(uidFromParam)
        setCompanyTitle(titleFromParam)
      } else {
        setCompanyUid(user.data.companyUid)
        setCompanyTitle(user.data.company.title)
      }
    }

    // eslint-disable-next-line
  }, [user])

  const { data: locationTypes } = useQuery(['locationTypes', { companyUid }], getLocationTypes, { enabled: !!companyUid })

  const { mutateAsync, isLoading } = useMutation(createLocation)

  const submitLocation = async (data) => {
    await mutateAsync(data)
  }

  const validationSchema = yup.object({
    name: yup.string().min(4, 'Too short').max(50, 'Too long').required('This field is required'),
    type: yup.string().required()
  })

  useEffect(() => {
    if (docs !== null) {
      setImageLoading(true)
      const file = docs[docs.length - 1]
      const fileToUpload = storage.ref(`digger/uploads/locations/${file.name}`)
      fileToUpload
        .put(file)
        .then(snap => {
          fileToUpload
            .getDownloadURL()
            .then(url => {
              setDlUrl(url)
              setImageLoading(false)
            })
        })
    }
  }, [docs])

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value)
    const latLng = await getLatLng(results[0])

    setGeo({
      addressString: value,
      point: {
        type: 'Point',
        coordinates: [latLng.lat, latLng.lng]
      }
    })
    setAddressString(value)
  }
  const edjsRef = useRef(null)

  useEffect(() => {
    if (edjsRef.current) {
      setEditor(new EditorJS({
        holder: 'edjs',
        tools: {
          header: {
            class: Header,
            inlineToolbar: ['link']
          },
          list: {
            class: List,
            inlineToolbar: [
              'link',
              'bold'
            ]
          },
          embed: {
            class: Embed,
            inlineToolbar: false,
            config: {
              youtube: true,
              coub: true
            }
          },
          image: {
            class: ImageTool,
            config: {
              uploader: {
                async uploadByFile (file) {
                  const storageRef = storage.ref()
                  const imagesRef = storageRef.child('EditorJS').child('images/' + file.name)
                  const metadata = {
                    contentType: 'image/jpeg'
                  }
                  const uploadTask = await imagesRef.put(file, metadata)
                  const downloadURL = await uploadTask.ref.getDownloadURL()
                  return {
                    success: 1,
                    file: {
                      url: downloadURL
                    }
                  }
                }
              }
            }
          }
        }
      }))
    }
  }, [])

  return (
    <>
      <Alert isActive={isActive} message={message} />
      <Formik
        validateOnChange
        initialValues={{
          name: '',
          type: '',
          location: ''
        }}
        validationSchema={validationSchema}
        onSubmit={async (data, { setSubmitting, resetForm }) => {
          setSubmitting(true)
          // DESTUCTURE FORM DATA
          const { name, type } = data
          if (geo) {
            const locationToUpload = {
              title: name,
              descriptionAsJSON: await editor.save().then(data => data),
              companyUid,
              photoUrl: dlUrl,
              locationTypeUid: type,
              address: geo.point,
              addressDescription: geo.addressString
            }
            submitLocation(locationToUpload)
          } else {
            openAlert('Please select a location')
          }
        }}
      >

        {({ values, isSubmitting }) => (
          <Form autoComplete='off'>
            <h2>Company: {companyTitle}</h2>
            <div className='createLocCard'>
              <div className='grid'>
                <div className='locForm'>
                  <div className='formContainer'>
                    <div className='formGroup'>
                      <label className='bold' htmlFor='locationName'>Location Name</label>
                      <Input
                        placeholder='Location Name'
                        name='name'
                        label='Name'
                        required
                      />
                    </div>

                    <div className='formGroup'>
                      <label className='bold' htmlFor='locationType'>Location Type</label>
                      <div className='locationType'>
                        <div className='toggle'>
                          {locationTypes ? locationTypes.map((type, i) => (
                            <React.Fragment key={type.uid}>
                              <Field key={type.uid} type='radio' name='type' value={type.uid} id={type.uid} />
                              <label htmlFor={type.uid}>{type.name}</label>
                            </React.Fragment>
                          )) : (<Loader />)}
                        </div>
                      </div>
                    </div>

                    <div className='formGroup'>
                      <label className='bold' htmlFor='location'>Location</label>
                      <PlacesAutocomplete
                        value={addressString}
                        onChange={setAddressString}
                        onSelect={handleSelect}
                      >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                          <div style={{ position: 'relative' }}>
                            <input {...getInputProps({ placeholder: 'Type Address' })} />
                            {suggestions.length > 0 && (
                              <div className='address-dropdown'>
                                {loading ? <Loader /> : null}
                                {suggestions.map((suggestion, i) => {
                                  const className = suggestion.active ? 'address-dropdown__item address-dropdown__item--active' : 'address-dropdown__item'
                                  return (
                                    <div
                                      key={i}
                                      {...getSuggestionItemProps(suggestion, {
                                        className
                                      })}
                                    >
                                      <p className='dropdown__icon'><ion-icon name='location-outline' /></p>
                                      <p className='address-dropdown__item__address'>{suggestion.formattedSuggestion.mainText} <span>{suggestion.formattedSuggestion.secondaryText}</span></p>
                                    </div>
                                  )
                                })}
                              </div>
                            )}
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </div>
                  </div>
                </div>
                <FileDropzone className='locImage locImage--location' setDocs={setDocs} preview={docs && docs.length > 0 && docs[0].preview}>
                  {imageLoading ? 'Uploading...' : 'Click or drag file to this area to upload'}
                </FileDropzone>
              </div>
            </div>
            <div className='requirementsCard' ref={edjsRef} id='edjs' />

            <button disabled={isLoading} className='btnPrimary' type='submit'>
              {isLoading ? 'Loading...' : 'Submit'}
            </button>
          </Form>
        )}
      </Formik>
    </>
  )
}
