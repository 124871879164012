import React, { useEffect } from 'react'

// PACKAGES
import ReactDom from 'react-dom'
import { useKeyDown } from '../../../hooks/utils/useKeyDown'

export const modalReducer = (state, action) => {
  const bodyNode = document.querySelector('body')
  const { type } = action
  switch (type) {
    case 'open':
      bodyNode.style.overflow = 'hidden'
      bodyNode.style.height = '100vh'
      return {
        ...state,
        open: true
      }
    case 'close':
      bodyNode.style.overflow = 'initial'
      return {
        ...state,
        open: false
      }
    default: {
      throw new Error(`Unsupported action type: ${type}`)
    }
  }
}

export const Modal = ({ customInner, children, open, closeModal, openModal, height }) => {
  const escPressed = useKeyDown('Escape')

  // eslint-disable-next-line
  useEffect(() => closeModal(), [escPressed])

  if (!open) return null
  return ReactDom.createPortal(
    <div className={`modalBackground modalShowing--${open}`}>
      {customInner ? children : (
        <div className='modalInner' style={height && { height }}>
          {children}
        </div>
      )}
    </div>,
    document.getElementById('portal')
  )
}
