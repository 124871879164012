import React, { useEffect, useState } from 'react'

// PACKAGES
import { Field, FieldArray, Form, Formik } from 'formik'
import { FileDropzone } from '../shared/forms/FileDropzone'
import { storage } from '../../firebase'
import { createCompany } from '../../constants/api'
import { Loader } from '../shared/Loader'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router'

export const CompanyCreate = () => {
  const [imageLoading, setImageLoading] = useState(false)
  const [docs, setDocs] = useState(null)
  const [dlUrl, setDlUrl] = useState(null)

  const history = useHistory()
  const { mutateAsync, isLoading } = useMutation(createCompany, {
    onSuccess: () => {
      history.push('/companies')
    }
  })

  const onSubmit = async (data) => {
    await mutateAsync({ ...data })
  }

  useEffect(() => {
    if (docs !== null) {
      setImageLoading(true)
      const file = docs[0]
      const fileToUpload = storage.ref(`digger/uploads/locations/${file.name}`)
      fileToUpload
        .put(file)
        .then(snap => {
          fileToUpload
            .getDownloadURL()
            .then(url => {
              setDlUrl(url)
              setImageLoading(false)
            })
        })
    }
  }, [docs])

  return (
    <>
      <Formik
        validateOnChange
        initialValues={{
          locationTypes: [{
            name: ''
          }]
        }}
        onSubmit={data => {
          onSubmit({
            title: data.name,
            photoUrl: dlUrl,
            name: data.locationType,
            locationTypes: data.locationTypes
          })
        }}

      >
        {({ values, isSubmitting }) => (
          <Form autoComplete='off'>
            <div className='requirementsCard'>
              <div className='formContainer'>

                <div className='formGroup'>
                  <label className='bold' htmlFor='name'>Company Name</label>
                  <Field
                    placeholder='Company Name'
                    name='name'
                    type='text'
                  />
                </div>
              </div>
              <FileDropzone className='locImage' setDocs={setDocs} preview={docs && docs.length > 0 && docs[0].preview}>
                {imageLoading
                  ? <Loader />
                  : docs
                    ? (
                      <div>
                        <h3>Change company image</h3>
                        <p>This will be seen across digger and you'll be recognized by this.</p>
                      </div>
                    )
                    : (
                      <div>
                        <h3>Upload a company image</h3>
                        <p>This will be seen across digger and you'll be recognized by this.</p>
                      </div>
                    )}
              </FileDropzone>
            </div>

            <h3>Location Types</h3>
            <p className='feedback'>These will be used to differentiate between your locations. E.g. Head Office and Remote</p>
            <div className='requirementsCard'>
              <FieldArray name='locationTypes'>
                {({ push, remove }) => (
                  <div>
                    {values.locationTypes.map((type, i) => {
                      return (
                        <div key={i} className='formContainer noGrid'>
                          <div className='formGroup formGroup--withBtn inline'>
                            <Field
                              type='text'
                              placeholder='Location Type'
                              name={`locationTypes[${i}].name`}
                            />
                          </div>
                          <button type='button' className='btnPrimary remove' onClick={() => remove(i)}>x</button>
                        </div>
                      )
                    })}
                    <button className='btnPrimary' type='button' onClick={() => push()}>
                      Add
                    </button>
                  </div>
                )}
              </FieldArray>
            </div>
            <button className='btnPrimary' type='submit'>{isLoading ? <Loader /> : 'Submit'}</button>
          </Form>
        )}
      </Formik>
    </>
  )
}
