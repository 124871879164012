import React, { createContext, useEffect, useState } from 'react'
import { Loader } from '../components/shared/Loader'
import { auth } from '../firebase'

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null)
  const [pending, setPending] = useState(true)

  useEffect(() => {
    auth.onAuthStateChanged(user => {
      setCurrentUser(user)
      setPending(false)
      if (user) {
        auth.currentUser.getIdTokenResult().then(idToken => {
          if (idToken.claims?.api.type !== 'admin') {
            auth.signOut()
            // openAlert('This account is not registered for digger admin', 'Error')
          }
        }).catch(error => console.error(error))
      } else {
        window.localStorage.clear()
      }
    })
    // eslint-disable-next-line
  }, [])

  if (pending) {
    return (
      <div className='pending__wrapper'>
        <div className='circle-1' />
        <div className='circle-2' />
        <h1>We're so glad to see you.</h1>
        <p>Bare with us as we sort everything out in the background</p>
        <Loader />
      </div>
    )
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
