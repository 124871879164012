import React from 'react'

// Packages
import { FieldArray } from 'formik'
import { ReactComponent as PlusIcon } from '../shared/icons/plus.svg'
import { Input } from '../shared/forms/Input'
import { Alert } from '../shared/Alert'
import { useAlert } from '../../hooks/useAlert'
import { uuidv4 } from '../../hooks/utils/useUuid'

export const CreateDocuments = ({ values, deletedDocs, setDeletedDocs, disabledAdd = false }) => {
  const { isActive, message, openAlert } = useAlert()

  const validateNewRequirement = (arr, push) => {
    if (arr.length > 0) {
      const latestRequirement = arr[arr.length - 1]
      if (latestRequirement.requirement === '') openAlert('Requirement cannot be empty')
      else push({ id: uuidv4(), requirement: '' })
    } else {
      push({ id: uuidv4(), requirement: '' })
    }
  }

  const removeDoc = (i, item) => {
    if (item?.disabled) setDeletedDocs([...deletedDocs, item])
  }

  return (
    <div className={values.documents.length < 1 ? 'requirementsCard outlined' : 'requirementsCard'}>
      <Alert isActive={isActive} message={message} />
      <FieldArray name='documents'>
        {({ push, remove }) => (
          <>
            {values.documents.length > 0 ? values.documents.map((doc, index) => (
              <div key={doc.id} className='formContainer'>
                <div className='formGroup'>
                  <label className='bold'>Document Name</label>
                  <div className='flex'>
                    <Input
                      placeholder='Document Name'
                      name={`documents[${index}].requirement`}
                      index={index}
                    />
                    {!disabledAdd && <button style={{ marginLeft: '.7rem' }} className='btnPrimary remove' onClick={() => removeDoc(index, doc) & remove(index)}>x</button>}
                  </div>
                </div>
              </div>
            )) : (
              <div
                className='card__content'
                onClick={() => validateNewRequirement(values.documents, push)}
              >
                <PlusIcon />
                <h3>Click here to start addings required documents</h3>
                <p>Adding a document will require the candidate to provide proof for each.</p>
              </div>
            )}

            {!disabledAdd && values.documents.length > 0 && (

              <button
                type='button'
                className='btnPrimary'
                onClick={() => validateNewRequirement(values.documents, push)}
                // push({ requirement: '' })
              >
              Add
              </button>
            )}
          </>
        )}
      </FieldArray>
    </div>
  )
}
