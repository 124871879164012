import React, { useContext } from 'react'
import { useQuery } from 'react-query'
import { NavLink } from 'react-router-dom'
import { getAdminUser } from '../../constants/api'
import { useGlobalStateContext } from '../../constants/UseCtx'
import { auth } from '../../firebase'
import { AuthContext } from '../../hooks/useAuth'
import { ReactComponent as SignoutIcon } from '../shared/icons/logout.svg'

export const Sidebar = () => {
  const { currentMenu } = useGlobalStateContext()
  const { currentUser } = useContext(AuthContext)
  const { data: user, isSuccess } = useQuery('user', getAdminUser,
    {
      staleTime: 1000 * 60 * 30,
      enabled: !!currentUser,
      refetchOnMount: false
    })
  if (currentMenu === 'closed' || !isSuccess) return null
  return (
    <div className='sidebarContainer'>
      <img src={user.data.company.photoUrl} className='siderbar-logo full' alt='company-logo' />
      <div className='sidebar-navGroup'>
        <h3>Job Listings</h3>
        <ul>
          <NavLink to='/jobs' className='sidebar-navItem' activeClassName='sidebar-navItem--active'>
            All Jobs
          </NavLink>
          <NavLink to='/job/create' className='sidebar-navItem' activeClassName='sidebar-navItem--active'>
            Create Jobs
          </NavLink>
          {/* <NavLink to='/shortlist' className='sidebar-navItem' activeClassName='sidebar-navItem--active'>
            Shortlist Manager
          </NavLink> */}
        </ul>
      </div>
      <div className='sidebar-navGroup'>
        <h3>Locations</h3>
        <ul>
          <NavLink to='/locations' className='sidebar-navItem' activeClassName='sidebar-navItem--active'>
            All Locations
          </NavLink>
          <NavLink to='/location/create' className='sidebar-navItem' activeClassName='sidebar-navItem--active'>
            Create Locations
          </NavLink>
        </ul>
      </div>
      {user.data.role === 'superAdmin' && (
        <div className='sidebar-navGroup'>
          <h3>Admin</h3>
          <ul>
            <NavLink to='/companies' className='sidebar-navItem' activeClassName='sidebar-navItem--active'>
               All Companies
            </NavLink>
            <NavLink to='/company/create' className='sidebar-navItem' activeClassName='sidebar-navItem--active'>
               Create Company
            </NavLink>
          </ul>
        </div>
      )}

      <div className='sidebar-navGroup sidebar-navGroup--bottom'>
        <button onClick={() => auth.signOut()} className='btnPrimary'><SignoutIcon /> Sign out</button>
      </div>
    </div>
  )
}
