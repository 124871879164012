import React from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useHistory, useParams } from 'react-router'
import { placeCandidate } from '../../constants/api'
import { useAlert } from '../../hooks/useAlert'
import { Alert } from '../shared/Alert'
import { Loader } from '../shared/Loader'
import { StatusPill } from '../shared/StatusPill'

export const ShortlistUser = ({ user, upvotes, downvotes }) => {
  // CONSTANTS
  const { slug: jobUid } = useParams()
  const queryClient = useQueryClient()
  const { push } = useHistory()
  const { isActive, message, openAlert } = useAlert()

  console.log(user.placementStatus)
  
  // API
  const { mutateAsync: runPlaceCandidate, isLoading } = useMutation(placeCandidate, {
    onSuccess: () => {
      queryClient.invalidateQueries('job')
      queryClient.invalidateQueries('jobs')
      push(window.location.pathname)
    },
    onError: (error) => openAlert(error.message)
  })
  return (
    <>
      <Alert isActive={isActive} message={message} />

      <div className='userCard'>
        <img src={user.candidate.photoUrl} alt='jobimage' className='userImage' />
        <div className='userContent'>
          <div className='name'>
            <h3>{user.candidate.fullName}</h3>
            <span>
              {user.status && (<StatusPill type message={user.status} />)}
            </span>
          </div>
          {user.candidate.educationLevel && (<p className='userDesc'>{user.candidate.educationLevel.capitalize()}</p>)}
          <p className='userInteractions'>

            {upvotes?.length} Upvotes | {downvotes?.length} Downvotes <span>·</span> {user.matchScore}% Match

          </p>
          {user?.placementStatus === 'offer-made' &&
          (
            <button
              onClick={async () => await runPlaceCandidate({
                candidateUid: user.candidate.uid,
                jobUid,
                jobCandidateUid: user.uid
              })}
              className='btnPrimary fit'
              disabled={isLoading}
            >
              {isLoading ? <Loader /> : 'Place Candidate'}
            </button>)}
        </div>
      </div>
    </>
  )
}
