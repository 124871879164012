import React, { useEffect, useRef } from 'react'
import EditorJS from '@editorjs/editorjs'
import Header from '@editorjs/header'
import List from '@editorjs/list'
import Embed from '@editorjs/embed'
import ImageTool from '@editorjs/image'
import { storage } from '../../firebase'

export const Editor = ({ id = 'edjs', initial, setInstance, disableImages = false }) => {
  const ejInstance = useRef()

  useEffect(() => {
    const initEditor = () => {
      setInstance(new EditorJS({
        holder: id,
        logLevel: 'ERROR',
        data: initial,
        autofocus: false,
        tools: {
          header: {
            class: Header,
            inlineToolbar: ['link']
          },
          list: {
            class: List,
            inlineToolbar: [
              'link',
              'bold'
            ]
          },
          embed: {
            class: Embed,
            inlineToolbar: false,
            config: {
              youtube: true,
              coub: true
            }
          },
          image: disableImages ? {} : {
            class: ImageTool,
            config: {
              uploader: {
                async uploadByFile (file) {
                  const storageRef = storage.ref()
                  const imagesRef = storageRef.child('EditorJS').child('images/' + file.name)
                  const metadata = {
                    contentType: 'image/jpeg'
                  }
                  const uploadTask = await imagesRef.put(file, metadata)
                  const downloadURL = await uploadTask.ref.getDownloadURL()
                  return {
                    success: 1,
                    file: {
                      url: downloadURL
                    }
                  }
                }
              }
            }
          }
          // image: {
          //   class: ImageTool,
          //   config: {
          //     uploader: {
          //       async uploadByFile (file) {
          //         const storageRef = storage.ref()
          //         const imagesRef = storageRef.child('EditorJS').child('images/' + file.name)
          //         const metadata = {
          //           contentType: 'image/jpeg'
          //         }
          //         const uploadTask = await imagesRef.put(file, metadata)
          //         const downloadURL = await uploadTask.ref.getDownloadURL()
          //         return {
          //           success: 1,
          //           file: {
          //             url: downloadURL
          //           }
          //         }
          //       }
          //     }
          //   }
          // }
        }
      }))
    }

    if (!ejInstance.current) {
      initEditor()
    }
    return () => {
      ejInstance.current = null
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div id={id} />
  )
}