import React, { useEffect, useRef } from 'react'
import EditorJS from '@editorjs/editorjs'
import Header from '@editorjs/header'
import List from '@editorjs/list'
import Embed from '@editorjs/embed'

export const TextOnlyEditor = ({ id = 'edjs', initial, setInstance }) => {
  const ejInstance = useRef()

  useEffect(() => {
    const initEditor = () => {
      setInstance(new EditorJS({
        holder: id,
        logLevel: 'ERROR',
        data: initial,
        autofocus: false,
        tools: {
          header: {
            class: Header,
            inlineToolbar: ['link']
          },
          list: {
            class: List,
            inlineToolbar: [
              'link',
              'bold'
            ]
          },
          embed: {
            class: Embed,
            inlineToolbar: false,
            config: {
              youtube: true,
              coub: true
            }
          }
        }
      }))
    }

    if (!ejInstance.current) {
      initEditor()
    }
    return () => {
      ejInstance.current = null
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div id={id} />
  )
}