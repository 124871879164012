import React, { useEffect, useReducer, useState } from 'react'

// ICONS
import { ReactComponent as LikeIcon } from '../shared/icons/like.svg'
import { ReactComponent as DislikeIcon } from '../shared/icons/dislike.svg'
import { ReactComponent as UserIcon } from '../shared/icons/user.svg'
import { ReactComponent as ArrowIcon } from '../shared/icons/arrow.svg'
import { Modal, modalReducer } from '../shared/layout/Modal'
import { ShortlistUser } from '../shortlist/ShortlistUser'
import { useMutation, useQueryClient } from 'react-query'
import { voteForCandidate } from '../../constants/api'
import { setEdjs } from '../../hooks/setEdjs'
import { useHistory, useLocation } from 'react-router'

export const Applicant = ({ applicant, jobUid }) => {
  // CONSTANTS
  const { push } = useHistory()
  function useQuery () {
    return new URLSearchParams(useLocation().search)
  }
  const query = useQuery()
  console.log(query)

  // BASIC STATE
  const [modal, modalDispatch] = useReducer(modalReducer, { open: false })
  const [upvotes] = useState(applicant.upvote)
  const [downvotes] = useState(applicant.downvote)
  const [selectedApplicant] = useState(() => query.get('applicant'))

  // QUERIES AND STATES
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(voteForCandidate, {
    onSuccess: () => {
      queryClient.invalidateQueries('job')
    }
  })

  const voteForApplicant = async (vote) => {
    await mutateAsync({
      vote,
      jobUid: jobUid,
      candidateUid: applicant.candidate.uid
    })
  }

  const eventInterceptor = e => {
    if (e.target.classList[0]?.includes('applicant') || e.target.classList[0]?.includes('applicantActions') || e.target.classList[0]?.includes('applicantAvatar') || e.target.tagName === 'H3') {
      handleOpenWithQueryParam('applicant', applicant.candidate.uid)
    } else {
      return null
    }
  }

  const handleOpenWithQueryParam = (name = 'name', payload = '') => {
    push(`${window.location.pathname}?${name}=${payload}`)
    modalDispatch({ type: 'open' })
  }
  const handleCloseWithQueryParam = () => {
    push(`${window.location.pathname}`)
    modalDispatch({ type: 'close' })
  }

  // EFFECTS
  // eslint-disable-next-line
  useEffect(() => { if (selectedApplicant && selectedApplicant === applicant.candidate.uid) modalDispatch({ type: 'open' }) }, [selectedApplicant])

  return (
    <>
      <Modal
        open={modal.open}
        closeModal={() => handleCloseWithQueryParam()}
        height='fit-content'
      >
        <div onClick={() => handleCloseWithQueryParam()} className='icon-button'><ArrowIcon /></div>
        <ShortlistUser upvotes={upvotes} downvotes={downvotes} user={applicant} />
        {/* <div className='flex'><h4 style={{ marginRight: '.3rem' }}>Upvotes: {upvotes.length}</h4><h4>Downvotes: {downvotes.length}</h4></div> */}
        <div className='horizontalDivider' />

        <div className='applicantModal__inner'>
          <div style={{ marginTop: '3rem' }} className='reqItem reqItem-experience'>
            <h3>Experience</h3>
            <ul>
              {applicant.candidate.candidateSkills.map((skill, i) => {
                return <p key={i} className='expBlock expBlock--green'><span>{skill.skill.name}</span> {skill.experience} Years</p>
              })}
            </ul>
          </div>
          {applicant.jobRequirementCandidateProofs.length > 0 &&
            (
              <div style={{ marginTop: '3rem' }} className='reqItem reqItem-experience'>
                <h3>Candidate Media</h3>
                <ul>
                  {applicant.jobRequirementCandidateProofs.map((doc, i) => {
                    return (
                      <a target='_blank' rel='noopener noreferrer' href={doc?.candidateMedia?.url} key={i}>
                        <p className='expBlock expBlock--blue'><span>{doc?.candidateMedia?.name}</span></p>
                      </a>
                    )
                  })}
                </ul>
              </div>
            )}
          <h3>Biography</h3>
          {applicant.candidate.biography || applicant.candidate.biographyAsDante ? (
            <div dangerouslySetInnerHTML={{ __html: setEdjs(applicant.candidate.biographyAsDante.blocks) }} />
          ) : (
            <p>This user does not have a biography...</p>
          )}
        </div>

      </Modal>
      <div className='applicantWrapper'>
        <div className='applicant' onClick={eventInterceptor}>
          <img src={applicant.candidate.photoUrl} alt='avatar' className='applicantAvatar' />
          <div className='applicantDetails'>
            <h3>{applicant.candidate.fullName}</h3>
          </div>
          <div className='applicantActions'>
            <p>{applicant.matchScore}%</p>
            <div
              onClick={() => voteForApplicant('upvote')}
              // onClick={() => voteForApplicant('upvote')}
              className={applicant.jobCandidateVotes[0]?.vote === 'upvote' ? 'action__icon active' : 'action__icon'}
            >
              <LikeIcon />
            </div>
            <div
              onClick={() => voteForApplicant('downvote')}
              // onClick={() => voteForApplicant('downvote')}
              className={applicant.jobCandidateVotes[0]?.vote === 'downvote' ? 'action__icon active' : 'action__icon'}
            >
              <DislikeIcon />
            </div>
            <div
              onClick={() => modalDispatch({ type: 'close' })}
              className='action__icon'
            >
              <UserIcon />
            </div>
            {/* <img src='/assets/icons/hide.png' alt='list' className='action' />
          <img src='/assets/icons/list.png' alt='list' className='action' /> */}
          </div>
        </div>
        <div className='horizontalDivider noMargin' />
      </div>
    </>
  )
}
