import React, { useState, useEffect } from 'react'
import { JobSkeleton } from '../jobs/JobSkeleton'
import { StatusPill } from '../shared/StatusPill'
import { setEdjs } from '../../hooks/setEdjs'

export const Location = ({ location, withGrid, loading }) => {
  const [descAsHtml, setDescAsHtml] = useState(null)

  useEffect(() => {
    if (!loading && location && location.descriptionAsJSON !== null) {
      // // const parsedData = JSON.parse(location.descriptionAsJSON)
      // console.log(location.descriptionAsJSON)
      // // const data = setEdjs(location.descriptionAsJSON.blocks, true)
      setDescAsHtml(setEdjs(location.descriptionAsJSON.blocks, true))
    }
  }, [loading, location])

  if (loading) {
    return (
      <div
        className='jobCard'
        style={{ display: 'block' }}
      >
        <div style={{ width: '100%', height: '10px', marginBottom: '1rem' }}><JobSkeleton /></div>
        <div style={{ width: '100%', height: '10px', marginBottom: '1rem' }}><JobSkeleton /></div>
        <div style={{ width: '100%', height: '10px', marginBottom: '1rem' }}><JobSkeleton /></div>
        <div style={{ width: '100%', height: '10px', marginBottom: '1rem' }}><JobSkeleton /></div>
      </div>
    )
  }

  return (
    <div className='jobCard withGrid'>
      <img src={location.photoUrl} alt='jobimage' className='jobImage' />
      <div className='jobContent'>
        <div className='jobTitle'>
          <h3>{location.title}</h3>
          <span>
            <StatusPill type={location.status} />
          </span>
        </div>

        <div id='html' className='jobDesc'>
          <div dangerouslySetInnerHTML={{ __html: descAsHtml }} />
        </div>
        <p className='jobInteractions'>
          {location?.jobs && `Jobs: ${location.jobs.length}`}
        </p>
      </div>
    </div>
  )
}
