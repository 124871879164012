import React from 'react'
import { CSSTransition } from 'react-transition-group'

export const Alert = ({ isActive, message, title, icon }) => {
  if (!message) return null
  return (
    <CSSTransition
      in={isActive}
      classNames='alert'
      timeout={500}
      unmountOnExit
    >
      <div className='alert__wrapper'>
        <div className='alert__accent' />
        <div className='alert__content'>
          {icon && (<div className='alert__icon'>{icon}</div>)}
          <div className='message__wrapper'>
            <p className='alert__title'>{message?.title}</p>
            <p className='alert__message'>{message?.msg}</p>
          </div>
        </div>
      </div>
    </CSSTransition>
  )
}
