import React, { useContext, useEffect, useMemo, useState } from 'react'

// Packages
import { DragDropContext } from 'react-beautiful-dnd'

// Icons
import { ReactComponent as MoreIcon } from '../shared/icons/more.svg'
import { ReactComponent as ArrowIcon } from '../shared/icons/arrow.svg'
import { ReactComponent as PlusIcon } from '../shared/icons/plus.svg'
import { ReactComponent as PenIcon } from '../shared/icons/pen.svg'
import { ReactComponent as DeleteIcon } from '../shared/icons/delete.svg'
import { ReactComponent as ChevronIcon } from '../shared/icons/chevron.svg'

// Component Imports
import { Applicants } from '../applicants/Applicants'
import { Shortlist } from '../shortlist/Shortlist'

// Data
// import { ClaimButton } from '../shared/claim/ClaimButton'
import { Link, useHistory, useParams } from 'react-router-dom'
import { deleteJob, createJobAdmin, moveToApplicantList, publishJob, shortlistCandidate, getCompanyAdmins, deleteJobAdmin, getAdminUser } from '../../constants/api'

import { Loader } from '../shared/Loader'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import dayjs from 'dayjs'
import { useGlobalDispatchContext, useGlobalStateContext } from '../../constants/UseCtx'
import { Dropdown } from '../shared/layout/Dropdown'
import { Alert } from '../shared/Alert'
import { useAlert } from '../../hooks/useAlert'
import { setEdjs } from '../../hooks/setEdjs'
import { useJob } from '../../hooks/useJobs'
import { AuthContext } from '../../hooks/useAuth'

export const SelectedJob = () => {
  const [columns, setColumns] = useState([])
  const [adminToUpdateUid, setAdminToUpdateUid] = useState('')
  const { slug: jobUid } = useParams()
  const { ctxMenu } = useGlobalStateContext()
  const dispatch = useGlobalDispatchContext()
  const history = useHistory()
  const queryClient = useQueryClient()
  const { isActive, message, openAlert } = useAlert()
  const [open, setOpen] = useState(false)
  const { currentUser } = useContext(AuthContext)

  // // QUERY STATE
  const { data: user, isLoading: userLoading, isError: userError } = useQuery('user', getAdminUser, { staleTime: 1000 * 60 * 30, enabled: !!currentUser })
  const jobQuery = useJob(jobUid)
  const { data: admins, isLoading: adminsLoading } = useQuery('admins', getCompanyAdmins, { staleTime: 1000 * 60 * 5, refetchOnMount: false })
  const { mutateAsync, isLoading: publishingJob } = useMutation(publishJob, {
    onSuccess: () => {
      queryClient.invalidateQueries('job')
      queryClient.invalidateQueries('jobs')
    }
  })
  const { mutateAsync: shortlistAsync } = useMutation(shortlistCandidate)
  const { mutateAsync: applicantListAsync } = useMutation(moveToApplicantList)
  const { mutateAsync: createJobAdminAsync, isLoading: createAdminLoading } = useMutation(createJobAdmin, {
    onSuccess: () => {
      openAlert('Success')
      queryClient.invalidateQueries('admins')
      queryClient.invalidateQueries('job')
    },
    onError: (e) => openAlert(e.response.data.message),
    onSettled: () => setAdminToUpdateUid('')
  })
  const { mutateAsync: deleteAdmin } = useMutation(deleteJobAdmin, {
    onSuccess: () => {
      openAlert('Success')
      queryClient.invalidateQueries('admins')
      queryClient.invalidateQueries('job')
    },
    onError: (e) => openAlert(e.response.data.message)
  })
  const { mutateAsync: asnycJobDelete } = useMutation('deleteJob', deleteJob, {
    onSuccess: () => {
      queryClient.invalidateQueries('jobs')
      history.push('/')
    }
  })

  const onPublish = async () => {
    await mutateAsync(jobUid)
  }

  const onShortlist = async (candidateUid, jobCandidateUid) => {
    await shortlistAsync({
      candidateUid,
      jobUid,
      jobCandidateUid
    })
  }
  const onApplicantList = async (candidateUid, jobCandidateUid) => {
    await applicantListAsync({
      candidateUid,
      jobUid,
      jobCandidateUid
    })
  }
  const onDelete = async () => {
    await asnycJobDelete(jobQuery.data.uid)
  }
  const onAdmin = async (role, adminUid) => {
    setAdminToUpdateUid(adminUid)
    await createJobAdminAsync({
      jobUid,
      adminUid,
      role
    })
  }

  useEffect(() => {
    if (!jobQuery.isLoading && jobQuery.data) {
      setColumns({
        '370ecba2-d2dd-4f21-a630-9dedc7e649c7': {
          name: 'Applicants',
          items: jobQuery.data.jobCandidates.filter(jc => { return jc.status === 'applicant' })
        },
        'cc4523f2-6f9e-4a66-ae4c-97e7c4e732a6': {
          name: 'Shortlist',
          items: jobQuery.data.jobCandidates.filter(jc => { return jc.status === 'shortlist' })
        }
      })
    }
    // eslint-disable-next-line
  }, [jobQuery.data, jobQuery.isLoading])

  useEffect(() => {
    if (!jobQuery.isLoading && jobQuery.data) {
      dispatch({ type: 'CTX_MENU', data: JSON.stringify(jobQuery.data) })
    }

    return () => dispatch({ type: 'CTX_MENU', data: null })
  }, [ctxMenu, jobQuery.data, jobQuery.isLoading, dispatch])

  const colors = ['yellow', 'blue', 'green', 'yellow', 'blue', 'green', 'yellow', 'blue', 'green', 'yellow', 'blue', 'green']

  const onDragEnd = async (result, columns, setColumns) => {
    if (!result.destination) return

    const { source, destination } = result

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId]
      const destColumn = columns[destination.droppableId]
      const sourceItems = [...sourceColumn.items]
      const destItems = [...destColumn.items]
      const [removed] = sourceItems.splice(source.index, 1)
      destItems.splice(destination.index, 0, removed)
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems
        }
      })
      if (destColumn.name === 'Shortlist') {
        await onShortlist(sourceColumn.items[0].candidateUid, sourceColumn.items[0].uid)
      } else if (destColumn.name === 'Applicants') {
        await onApplicantList(sourceColumn.items[0].candidateUid, sourceColumn.items[0].uid)
      }
    } else {
      const column = columns[source.droppableId]
      const copiedItems = [...column.items]
      const [removed] = copiedItems.splice(source.index, 1)
      copiedItems.splice(destination.index, 0, removed)
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems
        }
      })
    }
  }

  const determinedAdmins = useMemo(() => {
    if (!adminsLoading && !jobQuery.isLoading && admins && jobQuery.data) {
      let potentialAdmins = []
      potentialAdmins = admins.data.filter(admin => {
        if (jobQuery.data.createdByUid === admin.uid) return false
        else if (jobQuery.data.jobAdmins.findIndex((jAdmin) => {
          return admin.uid === jAdmin.adminUid
        }) > -1) return false
        else return true
      })
      return {
        potential: potentialAdmins
      }
    }
    // eslint-disable-next-line
  }, [admins, adminsLoading, jobQuery.isLoading])

  return (
    <>
      {!jobQuery.isLoading && jobQuery.data
        ? (
          <>
            <Alert isActive={isActive} message={message} />
            <div style={{ justifyContent: 'flex-end' }} className='flex'>
              {!jobQuery.data.datePublished &&
                <button onClick={onPublish} className='btnPrimary fit'>
                  {publishingJob ? <Loader /> : 'Publish Job'}
                </button>}
              {dayjs().isAfter(jobQuery.data.datePublished) && <div className='btnSecondary fit'>{jobQuery?.data?.status === 'fulfilled' ? 'Candidate Placed' : `Expires: ${dayjs(jobQuery.data.visibleUntil).format('DD/MM/YYYY')}`}</div>}

              <Dropdown open={open} setOpen={setOpen} icon={<MoreIcon />}>
                <Dropdown.Menu name='main'>
                  <Dropdown.Item goToMenu='createAdmins' rightIcon={<ChevronIcon />} leftIcon={<PlusIcon />}>Add Admin</Dropdown.Item>
                  <Dropdown.Item goToMenu='admins' rightIcon={<ChevronIcon />} leftIcon={<PlusIcon />}>Admins</Dropdown.Item>
                  {!jobQuery.data.datePublished ? <Dropdown.Link href={`/jobs/${jobQuery.data.uid}/edit`} leftIcon={<PenIcon />}>Edit</Dropdown.Link> : <></>}
                  {!userLoading && !userError && user && (
                    <>
                      {(user.data.role === 'superAdmin' || user.data.role === 'admin' || jobQuery.data.jobAdmins.find(x => (x.adminUid === user.data.uid && x.role === 'editor'))) && (
                        <Dropdown.Item callback={onDelete} leftIcon={<DeleteIcon />}>Delete</Dropdown.Item>
                      )}
                    </>
                  )}
                </Dropdown.Menu>

                <Dropdown.Menu customWidth='350px' isSecondary name='createAdmins'>
                  <Dropdown.Item marginBottom goToMenu='main' leftIcon={<ArrowIcon />}>
                    <h2>Company Admins</h2>
                  </Dropdown.Item>
                  {adminsLoading
                    ? <Loader />
                    : admins.data && jobQuery.data && admins.data.length > 0
                      ? (
                        determinedAdmins.potential.map(admin => (
                          <Dropdown.Item image={admin.photoUrl} loading={createAdminLoading && adminToUpdateUid === admin.uid && true} actions={[{ title: 'Editor', action: () => onAdmin('editor', admin.uid) }, { title: 'Viewer', action: () => onAdmin('editor', admin.uid) }]} key={admin.uid}>
                            {admin.fullName}
                            <Dropdown.Caption>Company: {admin.role}</Dropdown.Caption>
                          </Dropdown.Item>
                        ))
                      )
                      : <div>No Admins</div>}
                </Dropdown.Menu>
                <Dropdown.Menu isSecondary name='admins'>
                  <Dropdown.Item goToMenu='main' leftIcon={<ArrowIcon />}>
                    <h2>Admins</h2>
                  </Dropdown.Item>
                  {jobQuery.isLoading
                    ? <Loader />
                    : jobQuery.data && jobQuery.data.jobAdmins && jobQuery.data.jobAdmins.length > 0
                      ? (
                        jobQuery.data.jobAdmins.map(admin => {
                          if ('admin' in admin) {
                            return (
                              <Dropdown.Item image={admin.admin.photoUrl} rightIcon={<DeleteIcon />} callback={async () => await deleteAdmin({ adminUid: admin.adminUid, jobUid: admin.jobUid })} key={admin.admin.uid}>
                                {admin.admin.fullName}
                                <Dropdown.Caption>{admin.role}</Dropdown.Caption>
                              </Dropdown.Item>
                            )
                          } else return <></>
                        })
                      )
                      : <div>No Admins</div>}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <details style={{ marginTop: '-3.5rem' }} open>
              <summary style={{ fontSize: '1.5rem', padding: '1.5rem 0 1.5rem 0', fontWeight: '700' }}>
                Job Details
              </summary>

              <div className='previewCard true'>
                <div className='previewSummary'>
                  <div style={{ width: 'calc(50% - 2em)', overflow: 'hidden', borderRadius: '.9em' }}>
                    <img src={jobQuery.data.photoUrl} className='previewMedia' alt='job' />
                  </div>
                  <div className='previewMeta'>
                    <h2>{jobQuery.data.title}</h2>
                    <div className='metaContainer'>
                      <p className='metaBlock metaBlock--status'>
                        {jobQuery.data.isPublished ? 'Published' : 'Unpublished'}
                      </p>
                      <p className='metaBlock metaBlock--visibility'>
                        {jobQuery.data.isPublic ? 'Public' : 'Private'}
                      </p>
                      <p className='metaBlock metaBlock--createdBy'>{jobQuery.data.createdBy?.fullName}</p>
                    </div>
                    <div className='detailsContainer'>
                      <div><img src='/assets/icons/pin-image.png' alt='svg' />{jobQuery.data.location.title}</div>
                      <div><img src='/assets/icons/clipboard-image.png' alt='svg' />{!jobQuery.data.isPartTime ? 'Full Time' : 'Part Time'}</div>
                      <div><img src='/assets/icons/money-image.png' alt='svg' /> R {jobQuery.data.maxSalary} | Visible</div>
                    </div>
                  </div>
                </div>
                <div className='horizontalDivider' />
                <div className='previewRequirements'>
                  {jobQuery.data.jobRequirements && jobQuery.data.jobRequirements.length > 0 && (
                    <>
                      <div className='reqItem reqItem-education'>
                        <h3>Requirements</h3>
                        <ul>
                          {jobQuery.data.jobRequirements.map((req, i) => (

                            <li key={i}><img src='/assets/icons/diploma-image.png' alt='svg' />{req.requirement}</li>
                          ))}
                        </ul>
                      </div>
                    </>
                  )}
                  <div className='reqItem reqItem-experience'>
                    <h3>Experience</h3>
                    <ul>
                      {jobQuery.data.jobSkills.map((skill, i) => {
                        return <p key={skill.uid} className={`expBlock expBlock--${colors[i]}`}><span>{skill.name || skill.skill.name}</span> {skill.experience} Years</p>
                      })}
                    </ul>
                  </div>
                </div>
                <div className='horizontalDivider' />
                <div className='previewDesc editor' dangerouslySetInnerHTML={{ __html: setEdjs(jobQuery.data.descriptionAsJSON.blocks) }} />
              </div>
            </details>

            <DragDropContext onDragEnd={result => onDragEnd(result, columns, setColumns)}>

              <div className='jobInteractions'>
                {Object.entries(columns).map(([id, column]) => {
                  return (
                    column.name === 'Applicants'
                      ? <Applicants key={id} appList={column.items} jobUid={jobQuery.data.uid} id={id} />
                      : column.name === 'Shortlist'
                        ? <Shortlist key={id} shortlist={column.items} loading={jobQuery.isLoading} id={id} />
                        : null
                  )
                })}
              </div>
            </DragDropContext>
          </>
        )
        : jobQuery.isError
          ? (
            <>
              <h3>Can't find this job</h3>
              <Link className='btnPrimary' to='/jobs'>Back to the jobs</Link>
            </>
          )
          : (
            <Loader />
          )}
    </>
  )
}
