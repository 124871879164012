import React, { useMemo } from 'react'
import { StatusPill } from '../shared/StatusPill'
import { JobSkeleton } from './JobSkeleton'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Link } from 'react-router-dom'

dayjs.extend(relativeTime)

export const Job = ({ job, loading }) => {
  const jobStatus = useMemo(() => {
    if (job) {
      if (!job.datePublished) return 'Draft'
      else if (job.datePublished && dayjs().isBefore(job.visibleUntil) && job.isPrivate) return 'Private'
      // else if (job.datePublished && dayjs().isBefore(job.visibleUntil)) return 'Published')
      else return 'Published'
    }
  }, [job])

  if (loading) {
    return (
      <div
        className='jobCard'
        style={{ display: 'block' }}
      >
        <div style={{ width: '100%', height: '10px', marginBottom: '1rem' }}><JobSkeleton /></div>
        <div style={{ width: '100%', height: '10px', marginBottom: '1rem' }}><JobSkeleton /></div>
        <div style={{ width: '100%', height: '10px', marginBottom: '1rem' }}><JobSkeleton /></div>
        <div style={{ width: '100%', height: '10px', marginBottom: '1rem' }}><JobSkeleton /></div>
      </div>
    )
  }

  return (
    <Link to={`/jobs/${job.uid}`} className='jobCard'>
      <img src={job.photoUrl} alt='jobimage' className='jobImage' />
      <div className='jobContent'>
        <div className='jobTitle'>
          <h3>{job.title}</h3>
          <span>
            <StatusPill
              type={
                jobStatus === 'Draft'
                  ? 'Success'
                  : jobStatus === 'Private'
                    ? 'Danger'
                    : 'Warning'
              }
              message={jobStatus}
            />
          </span>
        </div>
        {/* <div className='jobDesc'> */}
        <div
          style={{
            maxHeight: '4rem',
            overflow: 'hidden'
          }}
        >
          {/* <Dante className='jobDesc' read_only content={job.descriptionAsJSON} /> */}
        </div>
        {/* {job.description} */}
        {/* </div> */}
        <p className='jobInteractions'>
          {/* {dayjs.utc(job.createdAt).local().fromNow()} <span>·</span> {job.meta.applicants} Applicants */}
        </p>
      </div>
    </Link>
  )
}
