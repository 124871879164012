import React, { useContext, useEffect, useState } from 'react'

// ICONS
import { ReactComponent as YoutubeIcon } from '../shared/icons/youtube.svg'

// PACKAGES
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

// COMPONENTS
import { auth } from '../../firebase'
import { getAdminUser } from '../../constants/api'
import { useGlobalDispatchContext } from '../../constants/UseCtx'
import { Loader } from '../shared/Loader'
import { useAlert } from '../../hooks/useAlert'
import { Alert } from '../shared/Alert'
import { Input } from '../shared/forms/Input'
import { useQuery } from 'react-query'
import { AuthContext } from '../../hooks/useAuth'
import { Link } from 'react-router-dom'

// CONSTANTS
const initialValues = {
  email: '',
  password: ''
}

const loginSchema = Yup.object().shape({
  email: Yup.string().email().required('Required'),
  password: Yup.string().required()
})

export const Login = () => {
  const [makeRequest, setMakeRequest] = useState(false)
  const [loading, setLoading] = useState(false)

  // CONSTANTS
  const dispatch = useGlobalDispatchContext()

  const { currentUser } = useContext(AuthContext)
  const { isActive, message, openAlert } = useAlert()
  const { data, isError, isSuccess, error } = useQuery('user', getAdminUser, { enabled: makeRequest && !!currentUser, staleTime: 1000 * 60 * 30 })

  useEffect(() => {
    dispatch({ type: 'TOGGLE_MENU', theme: 'closed' })
    if (isSuccess && !data.success) {
      openAlert(data.message, 'Error')
      setLoading(false)
      auth.signOut()
      dispatch({ type: 'TOGGLE_MENU', theme: 'closed' })
    }

    if (isError) {
      openAlert(error?.message, 'Error')
      setLoading(false)
      auth.signOut()
      dispatch({ type: 'TOGGLE_MENU', theme: 'closed' })
    }
    // eslint-disable-next-line
  }, [isError, isSuccess])

  return (
    <div className='authPage__wrapper' style={{ backgroundImage: 'url(/assets/iamges/yellowBg.jpg)' }}>
      <Alert isActive={isActive} message={message} />
      <div className='auth__container'>
        <div className='auth__content'>
          <h3>Digger.</h3>
          <p>Where jobs find you</p>

          <div className='socials__container'>
            <div className='social__item'>
              <div className='social__image'>
                <YoutubeIcon />
              </div>
              <div className='social__details'>
                <p className='social__title'>Digger Learning</p>
                <p className='social__body'>Find us on youtube.</p>
              </div>
            </div>
          </div>

        </div>
        <div className='auth__form'>
          <h3>Log In</h3>
          <p className='auth__ctxMessage'>New to digger? <span>Get in contact</span></p>
          <Formik
            initialValues={initialValues}
            validationSchema={loginSchema}
            validateOnChange
            onSubmit={(data, { resetForm, setSubmitting }) => {
              setLoading(true)

              auth.signInWithEmailAndPassword(data.email, data.password).then(async (res) => {
                setMakeRequest(true)
              })
                .catch(error => {
                  openAlert(error.message, 'Error')
                  setLoading(false)
                  setSubmitting(false)
                })
            }}
          >
            {({ values, errors, isSubmitting, dirty, isValid }) => (
              <Form autoComplete='off'>
                <div className='formContainer'>
                  <div className='formGroup noMargin'>
                    <label>Email</label>
                    <Input
                      name='email'
                      label='Email'
                      placeholder='example@email.com'
                      required
                    />
                  </div>
                  <div className='formGroup noMargin'>
                    <label>Password</label>
                    <Input
                      name='password'
                      label='Password'
                      placeholder='******'
                      type='password'
                      required
                    />
                  </div>
                  <button className='btnPrimary fullWidth' disabled={(loading || !dirty || !isValid) && true}>
                    {isSubmitting || loading ? <Loader /> : 'Continue'}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          <div className='alt__links'>
            <Link className='button__link' to='/forgot-password'>Forgot your password?</Link>
          </div>
        </div>
      </div>
    </div>
  )
}
