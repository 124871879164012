import { useEffect, useState } from 'react'

export function useAlert () {
  const [isActive, setIsActive] = useState(false)
  const [message, setMessage] = useState()

  useEffect(() => {
    if (isActive === true) {
      setTimeout(() => {
        setIsActive(false)
      }, 3000)
    }
  }, [isActive])

  const openAlert = (msg = 'Something went wrong...', title = '') => {
    setMessage({ msg, title })
    setIsActive(true)
  }

  return { isActive, message, openAlert }
}
