import React from 'react'

// Components
import { ShortlistItem } from './ShortlistItem'

// Packages
import { Droppable, Draggable } from 'react-beautiful-dnd'
import { Loader } from '../shared/Loader'

export const Shortlist = ({ shortlist, id, loading }) => {
  return (
    <div className='applicantListContainer'>
      <h3>Shortlist</h3>

      <div className='appCard appCard--filterable'>
        <Droppable droppableId={id} key='shortlist'>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {!loading ? shortlist.map((app, index) => (
                <Draggable
                  key={app.uid}
                  draggableId={app.uid}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                      style={{
                        userSelect: 'none',
                        ...provided.draggableProps.style
                      }}
                    >
                      <ShortlistItem applicant={app} />
                    </div>
                  )}
                </Draggable>
              )) : <Loader />}
            </div>
          )}
        </Droppable>
      </div>
    </div>
  )
}
