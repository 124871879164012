import { Field, Form, Formik } from 'formik'
import React, { useState } from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'
import { Location } from '../locations/Location'
import { ReactComponent as MoreIcon } from '../shared/icons/more.svg'
import { Dropdown } from '../shared/layout/Dropdown'
import * as Yup from 'yup'
import { useAlert } from '../../hooks/useAlert'
import { Alert } from '../shared/Alert'
import { createCompanyAdmin, deleteCompany, deleteCompanyAdmin, getAdminUser } from '../../constants/api'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Input } from '../shared/forms/Input'
import { ReactComponent as ArrowIcon } from '../shared/icons/arrow.svg'
import { ReactComponent as PlusIcon } from '../shared/icons/plus.svg'
import { ReactComponent as UserIcon } from '../shared/icons/user.svg'
import { ReactComponent as DeleteIcon } from '../shared/icons/delete.svg'
import { ReactComponent as PenIcon } from '../shared/icons/pen.svg'
import { Job } from '../jobs/Job'
import { useCompany } from '../../hooks/useCompanies'
import { Loader } from '../shared/Loader'

const validationSchema = Yup.object().shape({
  fullName: Yup.string().required('Required').min(5, 'Name must be longer'),
  email: Yup.string().email().required('Required'),
  role: Yup.string().required()
})

export const CompanyDetails = () => {
  const [open, setOpen] = useState(false)

  const { slug: companyUid } = useParams()
  const companyQuery = useCompany(companyUid)
  const queryClient = useQueryClient()
  const history = useHistory()

  const { mutateAsync: createAdminAsync, isSuccess: createAdminSuccess, isLoading: createAdminLoading } = useMutation(
    'createJobAdmin',
    createCompanyAdmin,
    {
      onError: (e) => {
        openAlert(e.response.data.message)
      },
      onSuccess: () => {
        openAlert('Successfully created admin')
      }
    }
  )
  const { mutateAsync: deleteAdmin } = useMutation(deleteCompanyAdmin, {
    onSuccess: () => {
      openAlert('Success')
      queryClient.invalidateQueries('company')
    },
    onError: (e) => openAlert(e.response.data.message)
  })
  const { data: user, isLoading } = useQuery('user', getAdminUser, { staleTime: 1000 * 60 * 30, refetchOnMount: false })

  const { isActive, message, openAlert } = useAlert()

  const { mutateAsync: asnycCompanyDelete } = useMutation('deleteJob', deleteCompany, {
    onSuccess: () => {
      queryClient.invalidateQueries('locations')
      history.push('/companies')
    }
  })

  const onDelete = async () => {
    await asnycCompanyDelete(companyQuery.data.uid)
  }

  if (companyQuery.isLoading) {
    return (
      <>
        <Job loading />
        <Job loading />
        <Job loading />
      </>
    )
  }

  return (
    <>
      <Alert isActive={isActive} message={message} />

      <div className='pageTitle'>
        <h3>Company Summary</h3>
        <div className='flex centerAlign'>
          <Dropdown open={open} setOpen={setOpen} icon={<MoreIcon />}>
            <Dropdown.Menu name='main'>
              <Dropdown.Item goToMenu='createAdmin' leftIcon={<PlusIcon />}>Create Admin</Dropdown.Item>
              <Dropdown.Item goToMenu='admins' leftIcon={<UserIcon />}>Admins</Dropdown.Item>
              <Dropdown.Link goToMenu='admins' href={`${window.location.pathname}/edit`} leftIcon={<PenIcon />}>Edit Company</Dropdown.Link>
              {!isLoading && user && (
                <>
                  {user.data.role === 'superAdmin' && (
                    <Dropdown.Item callback={onDelete} leftIcon={<DeleteIcon />}>Delete</Dropdown.Item>
                  )}
                </>
              )}
            </Dropdown.Menu>

            <Dropdown.Menu isSecondary name='createAdmin'>
              <Dropdown.Item marginBottom goToMenu='main' leftIcon={<ArrowIcon />}>
                <h2>Admins</h2>
              </Dropdown.Item>

              <Formik
                validateOnChange
                validationSchema={validationSchema}
                onSubmit={async (data, { resetForm }) => {
                  await createAdminAsync({
                    companyUid,
                    fullName: data.fullName,
                    email: data.email,
                    role: data.role
                  })
                  if (createAdminSuccess) resetForm()
                }}
                initialValues={{
                  fullName: '',
                  email: '',
                  role: 'user'
                }}
              >
                {({ values, errors, isSubmitting, dirty, isValid }) => (
                  <Form className='formContainer' autoComplete='off'>
                    <div className='formGroup'>

                      <label className='bold' htmlFor='fullName'>Full Name</label>
                      <Input placeholder='Full Name' name='fullName' />
                      <label className='bold' htmlFor='email'>Email</label>
                      <Input placeholder='Email' name='email' />
                      <label className='bold' htmlFor='title'>Duration</label>
                      <div className='toggle'>
                        <Field id='1' type='radio' name='role' value='user' checked />
                        <label htmlFor='1'>User</label>

                        <Field id='2' type='radio' name='role' value='admin' />
                        <label htmlFor='2'>Admin</label>
                      </div>
                      <button disabled={(!dirty || !isValid || createAdminLoading) && true} type='submit' style={{ marginTop: '1rem' }} className='btnPrimary fit'>Create Admin</button>
                    </div>

                  </Form>
                )}
              </Formik>

            </Dropdown.Menu>
            <Dropdown.Menu isSecondary name='admins'>
              <Dropdown.Item goToMenu='main' leftIcon={<ArrowIcon />}>
                <h2>Admins</h2>
              </Dropdown.Item>
              {!companyQuery.isFetching ? companyQuery.data?.admins.map(admin => {
                return (
                  <Dropdown.Item callback={async () => await deleteAdmin({ adminUid: admin.uid })} leftIcon={<UserIcon />} rightIcon={<DeleteIcon />} key={admin.uid}>
                    {admin.fullName}
                    <Dropdown.Caption>{admin.role}</Dropdown.Caption>
                  </Dropdown.Item>
                )
              })
                : <Loader />}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className='previewCard'>
        <div className='previewSummary'>
          <div style={{ width: 'calc(50% - 2em)', overflow: 'hidden', borderRadius: '.9em' }}>
            <img className='previewMedia' src={companyQuery.data.photoUrl} alt='location' />
          </div>
          <div className='previewMeta'>
            <h2>{companyQuery.data.title}</h2>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <p>Total Location:</p>
              <h3 style={{ marginLeft: '1rem' }} className='statBlock statBlock--jobs'>{companyQuery.data.locations.length}</h3>
            </div>
          </div>
        </div>
      </div>
      <h3>Locations</h3>
      {companyQuery.data.locations.length > 0
        ? (
          <div className='locations__grid'>
            {companyQuery.data.locations.map(location => (
              <Location withGrid key={location.uid} location={location} />
            ))}
            {user.data && !isLoading && user.data.role === 'superAdmin' && (
              <Link className='jobCard outlined' to={`/location/create?uid=${companyQuery.data.uid}&name=${companyQuery.data.title}`}>
                <div className='job__feedback'>
                  <PlusIcon />
                  <h3>New Location</h3>
                  <p>Build a location for this company</p>
                </div>
              </Link>
            )}
          </div>
        )
        : (
          <Link
            className='jobCard outlined'
            to={`/location/create?uid=${companyQuery.data.uid}&name=${companyQuery.data.title}`}
          >
            <div className='job__feedback'>
              <PlusIcon />
              <h3>No locations yet!</h3>
              <p>Build a location to start your Digger experience</p>
            </div>
          </Link>
        )}
    </>
  )
}
