import React from 'react'
import Dropzone from 'react-dropzone'

export const FileDropzone = ({ children, setDocs, disableClick, className, preview }) => {
  const handleDrop = acceptedFiles => {
    if (acceptedFiles.length > 0) {
      setDocs(acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      ))
    }
    console.log(acceptedFiles)
  }
  return (
    <Dropzone
      onDrop={handleDrop}
      accept='image/*'
      noClick={disableClick}
      onSubmit={(file) => console.log(file)}
      minSize={1024}
      maxSize={3072000}
    >
      {({
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
        fileRejections
      }) => {
        // additional CSS depends on dragging status
        const additionalClass = isDragAccept
          ? 'accept'
          : isDragReject
            ? 'reject'
            : ''

        const fileRejectionItems = fileRejections.map(({ file, errors }) => (
          <li className="error" key={file.path}>
            {file.path} - {file.size} bytes
            <ul>
              {errors.map(e => (
                <li key={e.code}>{e.message}</li>
              ))}
            </ul>
          </li>
        ))

        return (
          <div
            {...getRootProps({
              className: `${className} ${additionalClass}`
            })}
            style={{ backgroundImage: `url(${preview})` }}
          >
            <input {...getInputProps()} />
            {children}
            {fileRejectionItems}
          </div>
        )
      }}
    </Dropzone>
  )
}
