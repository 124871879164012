import React, { useEffect, useState } from 'react'

// Packages
import { Formik, Form, Field } from 'formik'
import { FileDropzone } from '../shared/forms/FileDropzone'
import { storage } from '../../firebase'
import * as yup from 'yup'
import { Input } from '../shared/forms/Input'
import { Loader } from '../shared/Loader'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { editLocation, getAdminUser, getLocationTypes, getSingleLocation } from '../../constants/api'
import { useHistory, useParams } from 'react-router'
import { Job } from '../jobs/Job'
import { Editor } from '../shared/Editor'

export const LocationEdit = () => {
  const [docs, setDocs] = useState(null)
  const [imageLoading, setImageLoading] = useState(false)
  const [editor, setEditor] = useState(null)
  const [dlUrl, setDlUrl] = useState('')
  const [companyUid, setCompanyUid] = useState(null)

  // CONSTANTS
  const { slug } = useParams()

  // API INTERACTION
  const { data: user, isSuccess } = useQuery('user', getAdminUser, { staleTime: 1000 * 60 * 30, refetchOnMount: false })

  useEffect(() => {
    if (isSuccess) {
      setCompanyUid(user.data.companyUid)
    }
    // eslint-disable-next-line
  }, [user])

  const queryClient = useQueryClient()

  const history = useHistory()

  const { data: location, isLoading: locationLoading } = useQuery(['location', { locationUid: slug }], getSingleLocation, {
    staleTime: 1000 * 60 * 5
  })
  const { data: locationTypes, isLoading: typesLoading } = useQuery(['locationTypes', { companyUid }], getLocationTypes, { enabled: !!companyUid })
  const { mutateAsync, isLoading } = useMutation(editLocation, {
    onSuccess: () => {
      queryClient.invalidateQueries('location')
      queryClient.invalidateQueries('locations')
      history.push('/locations')
    }
  })

  const submitLocation = async (data) => {
    await mutateAsync({
      locationUid: slug,
      updatedData: { ...data }
    })
  }

  const validationSchema = yup.object({
    name: yup.string().min(4, 'Too short').max(50, 'Too long').required('This field is required'),
    type: yup.string().required()
    // location: yup.string().required()
  })

  useEffect(() => {
    if (docs !== null) {
      setImageLoading(true)
      const file = docs[docs.length - 1]
      const fileToUpload = storage.ref(`digger/uploads/locations/${file.name}`)
      fileToUpload
        .put(file)
        .then(snap => {
          fileToUpload
            .getDownloadURL()
            .then(url => {
              setDlUrl(url)
              setImageLoading(false)
            })
        })
    }
  }, [docs])

  if (locationLoading || typesLoading) {
    return (
      <>
        <Job loading />
        <Job loading />
        <Job loading />
      </>
    )
  }

  return (
    <>
      {location.data && locationTypes && (
        <Formik
          validateOnChange
          initialValues={location && {
            name: location?.data.title,
            type: location?.data.locationType.uid,
            location: location?.data.addressDescription ? location?.data.addressDescription : ''
          }}
          validationSchema={validationSchema}
          onSubmit={async (data, { setSubmitting, resetForm }) => {
            setSubmitting(true)
            // DESTUCTURE FORM DATA
            const { name, type } = data
            const locationToUpload = {
              title: name,
              descriptionAsJSON: await editor.save().then(data => data),
              companyUid,
              photoUrl: dlUrl !== '' ? dlUrl : location.data.photoUrl,
              locationTypeUid: type
            }
            submitLocation(locationToUpload)
          }}
        >

          {({ values, isSubmitting }) => (
            <Form autoComplete='off'>
              <div className='createLocCard'>
                <div className='grid'>
                  <div className='locForm'>
                    <div className='formContainer'>
                      <div className='formGroup'>
                        <label className='bold' htmlFor='locationName'>Location Name</label>
                        <Input
                          placeholder='Location Name'
                          name='name'
                          label='Name'
                          required
                        />
                      </div>

                      <div className='formGroup'>
                        <label className='bold' htmlFor='locationType'>Location Type</label>
                        <div className='locationType'>
                          <div className='toggle'>
                            {locationTypes ? locationTypes.map((type, i) => (
                              <React.Fragment key={i}>
                                <Field type='radio' name='type' value={type.uid} id={type.uid} />
                                <label htmlFor={type.uid}>{type.name}</label>
                              </React.Fragment>
                            )) : (<Loader />)}
                          </div>
                        </div>
                      </div>

                      <div className='formGroup'>
                        <label className='bold' htmlFor='location'>Location</label>
                        <Field
                          placeholder='Location'
                          name='location'
                        />
                      </div>
                    </div>
                  </div>
                  <FileDropzone className='locImage' setDocs={setDocs} preview={docs && docs.length > 0 ? docs[0].preview : location.data.photoUrl}>
                    {imageLoading ? 'Uploading...' : 'Click or drag file to this area to upload'}
                  </FileDropzone>
                </div>
              </div>
              <div className='requirementsCard'>
                {/* <div ref={edjsRef} id='edjs' /> */}
                <Editor initial={location.data.descriptionAsJSON} setInstance={setEditor} />
              </div>
              <button disabled={isLoading} className='btnPrimary' type='submit'>
                {isLoading ? 'Loading...' : 'Submit'}
              </button>
            </Form>
          )}
        </Formik>
      )}
    </>
  )
}
